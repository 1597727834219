// Компонент для отображения стрелок сортировки в заголовках таблицы админ-панели

import React from 'react';
import '../../../../../styles/NewsTableRow.css'; // Импортируем стили

const SortArrows = ({ sortField, currentField, sortDirection }) => {
   // Если текущее поле не выбрано для сортировки, показываем обе стрелки неактивными
   if (sortField !== currentField) {
       return (
           <div className="sort-arrows">
               <span className="sort-arrow">▲</span>
               <span className="sort-arrow">▼</span>
           </div>
       );
   }

   // Показываем активную стрелку в зависимости от направления сортировки
   return (
       <span className={`sort-arrow ${sortDirection === 'asc' ? 'active' : ''}`}>
           {sortDirection === 'asc' ? '▲' : '▼'}
       </span>
   );
};

export default SortArrows;