import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import LogoutIcon from '@mui/icons-material/Logout';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import BuildIcon from '@mui/icons-material/Build';
import LanguageIcon from '@mui/icons-material/Language';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import axios from 'axios';
import { getLanguageName } from '../../utils/constants';

// Создадим глобальное событие для уведомления об изменении ширины сайдбара
export const SIDEBAR_RESIZE_EVENT = 'sidebar-resize';
// Создадим глобальное событие для уведомления об изменении статуса новости
export const NEWS_STATUS_CHANGE_EVENT = 'news-status-change';

// Экспортируем функцию для определения ширины сайдбара
export const getSidebarWidth = () => {
    const isCollapsed = localStorage.getItem('sidebarCollapsed');
    return isCollapsed && JSON.parse(isCollapsed) ? '5rem' : '16rem';
};

const AdminSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [isCollapsed, setIsCollapsed] = useState(() => {
        const saved = localStorage.getItem('sidebarCollapsed');
        return saved ? JSON.parse(saved) : false;
    });
    const [languageStats, setLanguageStats] = useState({});
    const [ctrStats, setCtrStats] = useState({
        ru: { views: 0, clicks: 0, ctr: 0, loading: true },
        pl: { views: 0, clicks: 0, ctr: 0, loading: true },
        ro: { views: 0, clicks: 0, ctr: 0, loading: true }
    });

    // Функция для загрузки статистики по языкам
    const fetchLanguageStats = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
            
            // Меняем API endpoint на базовый маршрут статистики
            const response = await axios.get('/api/admin/stats', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            
            // Преобразуем формат данных из response.data.languages в нужный для отображения
            if (response.data && response.data.languages) {
                // Инициализируем статистику для всех языков
                const stats = {
                    pl: { active: 0, inactive: 0 },
                    ru: { active: 0, inactive: 0 },                        
                    ro: { active: 0, inactive: 0 }
                };
                
                // Преобразуем формат данных из API в формат для отображения
                response.data.languages.forEach(lang => {
                    if (lang._id) {
                        stats[lang._id] = {
                            active: lang.active || 0,
                            inactive: lang.count - (lang.active || 0)
                        };
                    }
                });
                
                // Устанавливаем статистику в состояние компонента
                setLanguageStats(stats);
            }
        } catch (error) {
            console.error('Ошибка при загрузке статистики языков:', error);
        }
    };

    // Функция для загрузки статистики CTR по языкам
    const fetchCTRStats = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) return;
            
            // Получаем статистику для каждого языка
            const languages = ['ru', 'pl', 'ro'];
            const newStats = { ...ctrStats };
            
            for (const lang of languages) {
                try {
                    const response = await axios.get(`/api/admin/news-weight/average-ctr/${lang}`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    
                    if (response.data && response.data.success) {
                        newStats[lang] = {
                            views: response.data.data.totalViews || 0,
                            clicks: response.data.data.totalClicks || 0,
                            ctr: response.data.data.averageCTR || 0,
                            loading: false
                        };
                    }
                } catch (err) {
                    console.error(`Ошибка при загрузке CTR для ${lang}:`, err);
                    newStats[lang] = { ...newStats[lang], loading: false };
                }
            }
            
            setCtrStats(newStats);
        } catch (error) {
            console.error('Ошибка при загрузке статистики CTR:', error);
        }
    };

    useEffect(() => {
        localStorage.setItem('sidebarCollapsed', JSON.stringify(isCollapsed));
        // Отправляем кастомное событие для уведомления всех компонентов об изменении сайдбара
        window.dispatchEvent(new CustomEvent(SIDEBAR_RESIZE_EVENT, { 
            detail: { width: isCollapsed ? '5rem' : '16rem' } 
        }));
    }, [isCollapsed]);

    useEffect(() => {
        // Загрузка статистики при монтировании компонента
        fetchLanguageStats();
        fetchCTRStats();
        
        // Обновление статистики раз в минуту
        const intervalId = setInterval(() => {
            fetchLanguageStats();
            fetchCTRStats();
        }, 60000);
        
        // Обработчик события изменения статуса новости
        const handleNewsStatusChange = () => {
            fetchLanguageStats();
            fetchCTRStats();
        };
        
        // Добавляем слушатель события
        window.addEventListener(NEWS_STATUS_CHANGE_EVENT, handleNewsStatusChange);
        
        return () => {
            clearInterval(intervalId);
            window.removeEventListener(NEWS_STATUS_CHANGE_EVENT, handleNewsStatusChange);
        };
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/admin');
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    const menuItems = [
        {
            path: '/admin/dashboard',
            icon: <DashboardIcon />,
            label: 'Дашборд'
        },
        {
            path: '/admin/news',
            icon: <NewspaperIcon />,
            label: 'Объявления'
        },
        {
            path: '/admin/tools',
            icon: <BuildIcon />,
            label: 'Инструменты'
        }
    ];

    const isActive = (path) => location.pathname === path;

    return (
        <div className={`fixed h-screen flex flex-col bg-white border-r border-gray-200 transition-all duration-300 ${
            isCollapsed ? 'w-20' : 'w-64'
        }`}>
            {/* Логотип и кнопка сворачивания */}
            <div className="h-16 flex items-center justify-between px-6 border-b border-gray-200">
                {!isCollapsed && (
                    <span className="text-lg font-semibold text-gray-900">
                        Админ панель
                    </span>
                )}
                <button 
                    onClick={toggleSidebar}
                    className={`
                        flex items-center justify-center
                        ${isCollapsed ? 'w-8 mx-auto' : 'ml-auto'} 
                        h-8
                        border border-gray-200 rounded-lg
                        hover:bg-gray-50 transition-all
                        group
                    `}
                >
                    <div className="flex items-center">
                        {isCollapsed ? (
                            <>
                                <KeyboardArrowRightIcon />
                                <KeyboardArrowRightIcon className="-ml-4 text-indigo-600" />
                            </>
                        ) : (
                            <>
                                <KeyboardArrowLeftIcon />
                                <KeyboardArrowLeftIcon className="-ml-4 text-indigo-600" />
                            </>
                        )}
                    </div>
                </button>
            </div>

            {/* Навигация */}
            <nav className="flex flex-col px-4 py-3">
                {menuItems.map((item) => (
                    <Link
                        key={item.path}
                        to={item.path}
                        className={`
                            flex items-center gap-3 px-4 py-2.5
                            text-sm font-medium rounded-lg transition-colors
                            ${isCollapsed ? 'justify-center px-2' : ''}
                            ${isActive(item.path)
                                ? 'bg-indigo-50 text-indigo-600'
                                : 'text-gray-700 hover:bg-gray-50'
                            }
                        `}
                    >
                        <div className={`
                            flex items-center justify-center 
                            ${isActive(item.path) ? 'text-indigo-600' : 'text-gray-500'}
                        `}>
                            {item.icon}
                        </div>
                        {!isCollapsed && <span>{item.label}</span>}
                    </Link>
                ))}
                
                {/* Статистика объявлений внутри меню */}
                <div className={`mt-6 ${isCollapsed ? 'px-0' : 'px-2'}`}>
                    {!isCollapsed ? (
                        <div className="border-t pt-4 border-gray-100">
                            <div className="flex items-center mb-3 text-sm font-medium text-gray-700">
                                <LanguageIcon className="w-4 h-4 mr-2 text-gray-500" />
                                <span>Статистика объявлений:</span>
                            </div>
                            {Object.entries(languageStats).length > 0 ? (
                                <div className="space-y-2">
                                    {Object.entries(languageStats).map(([lang, stat]) => {
                                        const total = stat.active + stat.inactive;
                                        const activePercent = total > 0 ? Math.round((stat.active / total) * 100) : 0;
                                        
                                        return (
                                            <div key={lang} className="text-xs bg-gray-50 rounded p-1.5">
                                                <div className="flex items-center justify-between mb-1">
                                                    <span className="font-medium text-gray-700">{getLanguageName(lang)}</span>
                                                    <span className="text-xs text-gray-500">{total} всего</span>
                                                </div>
                                                
                                                <div className="w-full bg-gray-200 rounded-full h-1.5 mb-1">
                                                    {total > 0 && (
                                                        <div 
                                                            className="bg-indigo-500 h-1.5 rounded-full"
                                                            style={{ width: `${activePercent}%` }} 
                                                        />
                                                    )}
                                                </div>
                                                
                                                <div className="flex justify-between text-xs">
                                                    <span className="text-green-600 flex items-center">
                                                        <span className="w-2 h-2 rounded-full bg-green-500 mr-1"></span>
                                                        <span className="whitespace-nowrap">Активные: {stat.active}</span>
                                                    </span>
                                                    <span className="text-red-600 flex items-center">
                                                        <span className="w-2 h-2 rounded-full bg-red-500 mr-1"></span>
                                                        <span className="whitespace-nowrap">Неактивные: {stat.inactive}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : (
                                <div className="text-xs text-gray-500 text-center py-1 bg-gray-50 rounded">
                                    Загрузка данных...
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className="flex justify-center border-t border-gray-100 pt-4">
                            <LanguageIcon className="text-gray-500 w-5 h-5" />
                        </div>
                    )}
                </div>
                
                {/* Статистика CTR по языкам */}
                <div className={`mt-4 ${isCollapsed ? 'px-0' : 'px-2'}`}>
                    {!isCollapsed ? (
                        <div className="border-t pt-4 border-gray-100">
                            <div className="flex items-center mb-3 text-sm font-medium text-gray-700">
                                <TrendingUpIcon className="w-4 h-4 mr-2 text-gray-500" />
                                <span>Статистика за 7 дней (Рекламные)</span>
                            </div>
                            
                            <div className="space-y-2">
                                {Object.entries(ctrStats).map(([lang, stats]) => (
                                    <div key={`ctr-${lang}`} className="text-xs bg-gray-50 rounded p-2">
                                        <div className="flex items-center justify-between mb-2">
                                            <span className="font-medium text-gray-700">{getLanguageName(lang)}</span>
                                        </div>
                                        
                                        {stats.loading ? (
                                            <div className="text-center text-gray-500 py-1">Загрузка...</div>
                                        ) : (
                                            <div className="grid grid-cols-3 gap-1">
                                                <div className="flex flex-col items-center p-1 bg-blue-50 rounded">
                                                    <span className="text-blue-600 font-semibold">{stats.views}</span>
                                                    <span className="text-gray-500 text-[10px]">Показы</span>
                                                </div>
                                                <div className="flex flex-col items-center p-1 bg-green-50 rounded">
                                                    <span className="text-green-600 font-semibold">{stats.clicks}</span>
                                                    <span className="text-gray-500 text-[10px]">Клики</span>
                                                </div>
                                                <div className="flex flex-col items-center p-1 bg-purple-50 rounded">
                                                    <span className="text-purple-600 font-semibold">{stats.ctr.toFixed(2)}%</span>
                                                    <span className="text-gray-500 text-[10px]">CTR</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <div className="flex justify-center border-t border-gray-100 pt-4">
                            <TrendingUpIcon className="text-gray-500 w-5 h-5" />
                        </div>
                    )}
                </div>
            </nav>

            <div className="flex-1"></div>

            {/* Кнопка выхода */}
            <div className={`border-t border-gray-200 ${
                isCollapsed ? 'w-20' : 'w-64'
            }`}>
                <button
                    onClick={handleLogout}
                    className={`flex items-center gap-3 px-6 py-4 w-full text-sm font-medium text-gray-700 hover:bg-gray-50 transition-colors ${
                        isCollapsed ? 'justify-center px-2' : ''
                    }`}
                >
                    <LogoutIcon className="text-gray-500" />
                    {!isCollapsed && 'Выйти'}
                </button>
            </div>
        </div>
    );
};

export default AdminSidebar;