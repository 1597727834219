import React from 'react';

const StatsCard = ({ title, value, date }) => {
    return (
        <div className="bg-white rounded-lg p-6 shadow-sm">
            <div className="flex flex-col">
                <h3 className="text-sm font-medium text-gray-500 mb-4">
                    {title}
                </h3>
                <div className="flex flex-col gap-1">
                    <span className="text-3xl font-semibold text-gray-900">
                        {value}
                    </span>
                    <span className="text-sm text-gray-400">
                        {date}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default StatsCard;