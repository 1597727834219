import React from 'react';

const NewsPreview = ({ 
    image,
    title,
    aspectRatio = '1:1',
    placeholderText 
}) => {
    const previewStyles = {
        container: {
            maxWidth: '300px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            padding: '20px',
            backgroundColor: 'white',
            borderRadius: '8px',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
        },
        wrapper: {
            width: '100%',
            height: '100%',
            aspectRatio: aspectRatio === '16:9' ? '16/9' : 
                        aspectRatio === '3:2' ? '3/2' : '1/1',
            position: 'relative',
            overflow: 'hidden',
            borderRadius: '3px'
        },
        image: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.5s'
        },
        gradient: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '180px',
            background: 'linear-gradient(to top, rgba(0,0,0,0.9), rgba(0,0,0,0.5), transparent)',
            pointerEvents: 'none'
        },
        title: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            padding: '18px 14px',
            margin: 0,
            zIndex: 40,
            pointerEvents: 'none',
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: '16px',
            lineHeight: 1.2
        }
    };

    return (
        <div style={previewStyles.container}>
            <div style={previewStyles.wrapper}>
                <img
                    src={image}
                    alt="Preview"
                    style={previewStyles.image}
                    onMouseOver={e => e.target.style.transform = 'scale(1.05)'}
                    onMouseOut={e => e.target.style.transform = 'scale(1)'}
                />
                <div style={previewStyles.gradient} />
                <div style={previewStyles.title}>
                    {title || placeholderText}
                </div>
            </div>
        </div>
    );
};

export default React.memo(NewsPreview);