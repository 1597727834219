import React from 'react';
import { Box } from '@mui/material';

const News = () => {
  return (
    <Box>
      {/* Здесь будет список новостей */}
    </Box>
  );
};

export default News;
