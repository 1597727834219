import React, { useEffect, useState } from 'react';
import { settingsAPI } from '../utils/api';

const CodeInjector = () => {
    const [settings, setSettings] = useState(null);

    // Проверяем, что мы не в админке
    const isAdminPage = window.location.pathname.startsWith('/admin');
    if (isAdminPage) {
        return null;
    }

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await settingsAPI.getPublicSettings();
                setSettings(response.data);
            } catch (error) {
                console.error('Ошибка при загрузке настроек:', error);
            }
        };
        fetchSettings();
    }, []);

    useEffect(() => {
        if (!settings?.footerCode) return;

        // Проверяем, был ли уже добавлен код
        if (document.querySelector('#injected-footer-code')) {
            return;
        }

        // Создаем временный контейнер для парсинга
        const temp = document.createElement('div');
        temp.innerHTML = settings.footerCode;

        // Создаем контейнер для вставки
        const container = document.createElement('div');
        container.id = 'injected-footer-code';

        // Обрабатываем каждый элемент из кода
        Array.from(temp.children).forEach(child => {
            if (child.tagName === 'SCRIPT') {
                // Для скриптов создаем новый элемент
                const script = document.createElement('script');
                
                // Копируем атрибуты
                Array.from(child.attributes).forEach(attr => {
                    script.setAttribute(attr.name, attr.value);
                });
                
                // Копируем содержимое
                script.textContent = child.textContent;
                
                // Добавляем скрипт
                container.appendChild(script);
            } else {
                // Остальные элементы копируем как есть
                container.appendChild(child.cloneNode(true));
            }
        });

        // Вставляем контейнер в конец body
        document.body.appendChild(container);

    }, [settings]);

    return null;
};

export default CodeInjector; 