// Утилиты для форматирования данных в админ-панели

import { LANGUAGES, FILTER_CONSTANTS } from './constants';

export const getLanguageName = (code) => {
    return LANGUAGES[code] || code;
};

export const formatDate = (date) => {
    return new Date(date).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

export const formatNumber = (number) => {
    if (!number) return '0';
    
    return new Intl.NumberFormat('ru-RU').format(number);
};

export const formatPercentage = (value) => {
    if (!value) return '0.00%';
    
    return `${value.toFixed(2)}%`;
};

export const formatStatus = (status) => {
    const statusMap = {
        'active': 'Запущен',
        'inactive': 'Остановлен',
    };
    return statusMap[status] || status;
};

export const formatFileSize = (bytes) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
};

export const formatStatusFilter = (status) => {
    return FILTER_CONSTANTS.STATUS_LABELS[status] || status;
};

export const formatLanguageFilter = (language) => {
    const languageMap = {
        [FILTER_CONSTANTS.LANGUAGES.ALL]: 'Все',
        [FILTER_CONSTANTS.LANGUAGES.RU]: 'Русский',
        [FILTER_CONSTANTS.LANGUAGES.PL]: 'Польский',
        [FILTER_CONSTANTS.LANGUAGES.RO]: 'Румынский'
    };
    return languageMap[language] || language;
};