import React from 'react';
import AdminLayout from '../components/Layout';
import NewsList from './News/NewsList';

const NewsPage = () => {
    return (
        <AdminLayout>
            <NewsList />
        </AdminLayout>
    );
};

export default NewsPage;