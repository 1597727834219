import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BASE_DOMAIN } from '../../utils/api';
import '../../../styles/NewsTableRow.css';
import ActionButtons from './TableComponents/Row/ActionButtons';
import NewsTitle from './TableComponents/Row/NewsTitle';
import StatsDisplay from './TableComponents/Row/StatsDisplay';
import NewsWeight from './TableComponents/Row/NewsWeight';
import { calculateStats } from '../../utils/statsCalculations';
import { FILTER_CONSTANTS } from '../../utils/constants';
import { getStatusFromBoolean } from '../../utils/statusHelpers';

const NewsTableRow = ({
    item,
    historicalStats,
    selectedDate,
    selectedItems,
    handleSelectItem,
    handleToggleActive,
    handleDelete,
    showMenu,
    setShowMenu,
    getLanguageName
}) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMenu]);

    // Получаем статистику
    const stats = calculateStats(item, historicalStats, selectedDate);

    return (
        <tr className={`hover:bg-gray-50 news-row ${!item.isActive ? 'opacity-50' : ''}`}>
            <td className="table-cell checkbox">
                <input
                    type="checkbox"
                    checked={selectedItems.has(item._id)}
                    onChange={() => handleSelectItem(item._id)}
                    className="checkbox-input"
                />
            </td>
            <td className="table-cell wide">
                <ActionButtons
                    item={item}
                    showMenu={showMenu}
                    setShowMenu={setShowMenu}
                    handleToggleActive={handleToggleActive}
                    handleDelete={handleDelete}
                    getLanguageName={getLanguageName}
                />
            </td>
            <td className="table-cell full">
                <div className="news-content-wrapper">
                    <div className="image-container">
                        <img 
                            src={`${BASE_DOMAIN}${item.imageUrl}`}
                            alt={item.title}
                            className="news-image"
                        />
                    </div>
                    <NewsTitle 
                        title={item.title}
                        sourceUrl={item.sourceUrl}
                        news={item}
                    />
                </div>
            </td>
            <NewsWeight news={item} />
            <StatsDisplay stats={stats} />
        </tr>
    );
};

export default NewsTableRow;