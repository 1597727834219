import React from 'react';
import '../../../../../styles/NewsTableFilters.css';

const ActionButton = ({ onClick, className, label, icon, disabled }) => (
    <button 
        onClick={onClick} 
        className={`action-button ${className}`}
        disabled={disabled}
    >
        {icon && <span className="button-icon">{icon}</span>}
        {label}
    </button>
);

export default ActionButton; 