import React, { useState, useEffect } from 'react';
import AdminSidebar, { getSidebarWidth, SIDEBAR_RESIZE_EVENT } from './Sidebar/AdminSidebar';

const AdminLayout = ({ children }) => {
    const [sidebarWidth, setSidebarWidth] = useState(getSidebarWidth);

    useEffect(() => {
        // Слушаем событие изменения ширины сайдбара
        const handleSidebarResize = (event) => {
            setSidebarWidth(event.detail.width);
        };

        // Добавляем слушатель события
        window.addEventListener(SIDEBAR_RESIZE_EVENT, handleSidebarResize);

        // Удаляем слушатель при размонтировании компонента
        return () => {
            window.removeEventListener(SIDEBAR_RESIZE_EVENT, handleSidebarResize);
        };
    }, []);

    return (
        <div className="flex min-h-screen bg-gray-100">
            <AdminSidebar />
            <main style={{ marginLeft: sidebarWidth }} className="flex-1 p-8 overflow-auto transition-all duration-300">
                {children}
            </main>
        </div>
    );
};

export default AdminLayout;