import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import '../../../../styles/NewsList.css';

const NewsHeader = ({ onStatsChange, newsStats }) => {
    const navigate = useNavigate();
    const [dateRangeExpanded, setDateRangeExpanded] = useState(false);
    
    // Обработчик изменения даты
    const handleDateChange = (stats, dateRange) => {
        // Определяем, выбран ли диапазон дат
        if (dateRange && dateRange.startDate && dateRange.endDate) {
            setDateRangeExpanded(dateRange.startDate.getTime() !== dateRange.endDate.getTime());
        }
        
        // Вызываем оригинальный обработчик
        if (onStatsChange) {
            onStatsChange(stats, dateRange);
        }
    };
    
    return (
        <div className="news-header-container">
            <div className="news-header-content">
                <div className="news-header">
                    <div className="news-header-content-inner">
                        <div className="news-header-section-left">
                            <h1 className="news-header-title">Объявления</h1>
                        </div>
                        
                        <div className="news-header-section-center">
                            {/* Центральная секция теперь свободна */}
                        </div>

                        <div className="news-header-section-right">
                            <div className="news-header-controls">
                                <button
                                    onClick={() => navigate('/admin/news/create')}
                                    className="news-header-primary-button"
                                >
                                    + Новое объявление
                                </button>
                                <div className={dateRangeExpanded ? "date-range-picker-container expanded" : "date-range-picker-container"}>
                                    <DateRangePicker onStatsChange={handleDateChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewsHeader; 