import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';

const NewsRedirect = ({ isAd }) => {
    const { slug } = useParams();
    const location = useLocation();

    useEffect(() => {
        const handleRedirect = async () => {
            try {
                const endpoint = isAd 
                    ? `/api/out/news/${slug}`
                    : `/api/forward/news/${slug}`;
                
                const response = await axios.get(endpoint);
                
                if (response.data && response.data.redirectUrl) {
                    window.location.href = response.data.redirectUrl;
                }
            } catch (error) {
                console.error('Ошибка редиректа:', error);
                window.location.href = '/';
            }
        };

        handleRedirect();
    }, [slug, isAd]);

    return null;
};

export default NewsRedirect; 