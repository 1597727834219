import React from 'react';

const Home = () => {
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'linear-gradient(135deg, #FF4B2B, #FF416C)',
      color: 'white',
      textAlign: 'center',
      fontFamily: 'Arial, sans-serif'
    }}>
      <h1 style={{
        fontSize: '4rem',
        marginBottom: '20px',
        textShadow: '2px 2px 4px rgba(0,0,0,0.3)'
      }}>
        LineNews
      </h1>
      <div style={{
        fontSize: '1.8rem',
        marginBottom: '30px',
        maxWidth: '600px',
        padding: '0 20px',
        fontWeight: 'bold'
      }}>
        Новый агрегатор новостей скоро будет доступен...
      </div>
      <div style={{
        padding: '20px',
        background: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '20px',
        backdropFilter: 'blur(10px)',
        border: '2px solid rgba(255,255,255,0.3)'
      }}>
        Следите за обновлениями 🚀
      </div>
    </div>
  );
};

export default Home;