import React from 'react';
import { api, BASE_DOMAIN } from '../../utils/api';

const ImageUpload = ({ 
    currentImage, 
    preview, 
    onImageChange, 
    onImageRemove, 
    isRequired,
    isEditing,
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {(preview || currentImage) && (
                <div style={{ position: 'relative', width: 'fit-content' }}>
                    <img 
                        src={preview || (currentImage ? `${BASE_DOMAIN}${currentImage}` : '')}
                        alt="Preview" 
                        style={{
                            width: '300px',
                            height: '180px',
                            objectFit: 'cover',
                            borderRadius: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                    />
                    <button
                        type="button"
                        onClick={onImageRemove}
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            backgroundColor: 'rgba(255,255,255,0.9)',
                            border: 'none',
                            borderRadius: '50%',
                            width: '24px',
                            height: '24px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            fontSize: '12px'
                        }}
                    >
                        ✕
                    </button>
                </div>
            )}
            
            <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <button
                    type="button" 
                    style={{
                        width: '100%',
                        padding: '12px 16px',
                        backgroundColor: '#2a5298',
                        color: 'white',
                        borderRadius: '4px',
                        border: 'none',
                        fontSize: '14px',
                        fontWeight: '500',
                        textAlign: 'center',
                        cursor: 'pointer',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                    }}
                    onClick={() => document.getElementById('file-upload').click()}
                >
                    {preview || currentImage ? 'Изменить изображение' : 'Выбрать изображение'}
                </button>
                <input
                    id="file-upload"
                    type="file"
                    accept="image/jpeg,image/png,image/webp"
                    onChange={onImageChange}
                    required={!isEditing && isRequired}
                    style={{ display: 'none' }}
                />
                
                {!preview && !currentImage && (
                    <div style={{ fontSize: '12px', color: '#666' }}>
                        Размер изображения не менее 500x300 px. Формат: JPG, PNG, WEBP
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUpload;