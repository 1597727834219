// Константы для использования в админ-панели

export const TABLE_CONSTANTS = {
    ROWS_PER_PAGE_OPTIONS: [25, 50, 100],
    DEFAULT_ROWS_PER_PAGE: 25,
    DEFAULT_PAGE: 1
 };
 
 export const LANGUAGES = {
    ru: 'Русский',
    pl: 'Польский',
    ro: 'Румынский'
 };
 
 export const FILTER_CONSTANTS = {
    STATUSES: {
        ALL: 'all',
        ACTIVE: 'active',
        INACTIVE: 'inactive'
    },
    STATUS_LABELS: {
        all: 'Все',
        active: 'Запущен',
        inactive: 'Остановлен'
    },
    LANGUAGES: {
        ALL: 'all',
        RU: 'ru',
        PL: 'pl',
        RO: 'ro'
    },
    BULK_ACTIONS: {
        ACTIVATE: 'activate',
        DEACTIVATE: 'deactivate',
        CHANGE_URL: 'changeUrl',
        DELETE: 'delete'
    }
};
 
 export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc'
 };
 
 export const SORT_FIELDS = {
    VIEWS: 'views',
    CLICKS: 'clicks',
    CTR: 'ctr',
    ADS_VIEWS: 'adsViews',
    ADS_CLICKS: 'adsClicks',
    ADS_CTR: 'adsCtr',
    DATE: 'createdAt'
 };
 
 export const API_ENDPOINTS = {
    NEWS: '/admin/news',
    STATS: '/admin/stats',
    SETTINGS: '/admin/settings',
    AUTH: '/admin/auth'
 };
 
 export const DATE_FORMAT = 'dd.MM.yyyy';

// Добавим функцию для получения названия языка
export const getLanguageName = (code) => LANGUAGES[code] || code;