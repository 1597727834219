import React, { useState, useRef, useEffect } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const ImageCropModal = ({ image, onComplete, onCancel }) => {
    const imgRef = useRef(null);
    const [modalDimensions, setModalDimensions] = useState({ width: 800, height: 'auto' });
    const [crop, setCrop] = useState({
        unit: 'px',
        width: 500,
        height: 300,
        x: 0,
        y: 0,
        aspect: 500/300
    });

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            const maxModalWidth = 800;
            const maxModalHeight = window.innerHeight * 0.9; // 90% от высоты окна
            const headerFooterHeight = 120; // Высота заголовка и футера
            
            const imageAspectRatio = img.height / img.width;
            let modalWidth = maxModalWidth;
            let modalHeight = modalWidth * imageAspectRatio + headerFooterHeight;

            // Если высота превышает максимально допустимую
            if (modalHeight > maxModalHeight) {
                modalHeight = maxModalHeight;
                const availableImageHeight = modalHeight - headerFooterHeight;
                modalWidth = availableImageHeight / imageAspectRatio;
            }

            setModalDimensions({
                width: modalWidth,
                height: modalHeight
            });
        };
        img.src = image;
    }, [image]);

    const getCroppedImg = (sourceImage, cropConfig) => {
        const canvas = document.createElement('canvas');
        const scaleX = sourceImage.naturalWidth / sourceImage.width;
        const scaleY = sourceImage.naturalHeight / sourceImage.height;
        canvas.width = 500;
        canvas.height = 300;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            console.error('Failed to get canvas context');
            return null;
        }

        try {
            ctx.drawImage(
                sourceImage,
                cropConfig.x * scaleX,
                cropConfig.y * scaleY,
                cropConfig.width * scaleX,
                cropConfig.height * scaleY,
                0,
                0,
                500,
                300
            );

            return new Promise((resolve) => {
                canvas.toBlob(blob => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        resolve(null);
                        return;
                    }
                    const croppedFile = new File([blob], 'cropped.jpg', { type: 'image/jpeg' });
                    resolve(croppedFile);
                }, 'image/jpeg', 1);
            });
        } catch (error) {
            console.error('Error during image cropping:', error);
            return null;
        }
    };

    const handleComplete = async () => {
        try {
            const imageElement = imgRef.current;
            if (!imageElement) {
                console.error('Image element not found');
                return;
            }

            if (!crop.width || !crop.height) {
                console.error('Invalid crop dimensions');
                return;
            }

            const croppedImage = await getCroppedImg(imageElement, crop);
            
            if (!croppedImage) {
                console.error('Failed to crop image');
                return;
            }

            onComplete(croppedImage);
        } catch (e) {
            console.error('Error in handleComplete:', e);
        }
    };

    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
        }}>
            <div style={{
                backgroundColor: 'white',
                padding: '20px',
                borderRadius: '8px',
                width: modalDimensions.width,
                height: modalDimensions.height,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden'
            }}>
                <h2 style={{ 
                    margin: '0 0 20px 0',
                    color: '#304878',
                    fontSize: '18px',
                    flexShrink: 0 // Предотвращаем сжатие заголовка
                }}>
                    Обрезка изображения
                </h2>
                <div style={{ 
                    flex: 1,
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ReactCrop
                        crop={crop}
                        onChange={newCrop => setCrop(newCrop)}
                        aspect={500/300}
                        style={{
                            maxHeight: '100%',
                            maxWidth: '100%'
                        }}
                    >
                        <img
                            ref={imgRef}
                            src={image}
                            alt="Crop preview"
                            style={{ 
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'contain'
                            }}
                        />
                    </ReactCrop>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '10px',
                    marginTop: '20px',
                    flexShrink: 0 // Предотвращаем сжатие футера
                }}>
                    <button
                        type="button"
                        onClick={onCancel}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#6c757d',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Отмена
                    </button>
                    <button
                        type="button"
                        onClick={handleComplete}
                        style={{
                            padding: '8px 16px',
                            backgroundColor: '#304878',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}
                    >
                        Подтвердить
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ImageCropModal;