import React, { useState, useEffect, useCallback, useRef } from 'react';
import { registerAdView, handleAdClick } from '../utils/newsInteractions';

const RelatedNewsBlock = ({ 
    currentNewsId, 
    currentLanguage,
    preparedNews = [], // Готовые новости от родителя
    allAvailableNews = [], // Все доступные новости для повторов
    topNewsId = null, // ID новости из верхнего блока
    itemsCount = null 
}) => {
    const [displayedNews, setDisplayedNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const viewedNews = useRef(new Set());
    const observerRef = useRef(null);
    const loadingRef = useRef(null);
    const ITEMS_PER_PAGE = 11;
    const MAX_PAGES = 7;

    // Инициализация первой страницы новостей
    useEffect(() => {
        if (preparedNews && preparedNews.length > 0) {
            // Берем первую страницу новостей
            const firstPage = preparedNews.slice(0, ITEMS_PER_PAGE);
            setDisplayedNews(firstPage);
            setHasMore(preparedNews.length > ITEMS_PER_PAGE);
            setCurrentPage(1);
        } else {
            setDisplayedNews([]);
            setHasMore(false);
        }
    }, [preparedNews]);

    const loadMoreNews = useCallback(() => {
        if (currentPage >= MAX_PAGES) {
            setHasMore(false);
            return;
        }

        const startIndex = currentPage * ITEMS_PER_PAGE;
        let nextNews = [];

        if (startIndex < preparedNews.length) {
            // Берем следующую порцию из подготовленных новостей
            nextNews = preparedNews.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        } else {
            // Для повторных новостей берем из общего пула, включая верхнюю новость
            
            // Фильтруем пул, исключая только текущую новость
            const availableForRepeat = allAvailableNews.filter(news => 
                news._id !== currentNewsId
                // Не исключаем топовую новость из повторов
            );
            
            // Перемешиваем для случайного отображения
            const shuffledForRepeat = [...availableForRepeat].sort(() => Math.random() - 0.5);
            
            nextNews = shuffledForRepeat
                .slice(0, ITEMS_PER_PAGE)
                .map(news => ({
                    ...news,
                    _id: `${news._id}-repeat-${currentPage}` // Метка для повторных новостей
                }));
        }

        if (nextNews.length > 0) {
            setDisplayedNews(prev => [...prev, ...nextNews]);
            setCurrentPage(prev => prev + 1);
            setHasMore(currentPage + 1 < MAX_PAGES);
        } else {
            setHasMore(false);
        }
    }, [currentPage, preparedNews, allAvailableNews, currentNewsId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && hasMore) {
                    loadMoreNews();
                }
            },
            { threshold: 0.1 }
        );

        if (loadingRef.current) {
            observer.observe(loadingRef.current);
        }

        return () => observer.disconnect();
    }, [loadMoreNews, hasMore]);

    useEffect(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const newsId = entry.target.dataset.newsId;
                        if (newsId && !viewedNews.current.has(newsId)) {
                            viewedNews.current.add(newsId);
                            const originalId = newsId.split('-repeat-')[0];
                            registerAdView(originalId);
                        }
                    }
                });
            },
            { threshold: 0.5 }
        );

        // Изменяем селектор, чтобы искать элементы только внутри RelatedNewsBlock,
        // а не по всему документу
        const containerElement = document.getElementById('related-news');
        if (containerElement) {
            const newsElements = containerElement.querySelectorAll('.news-item:not(.top-news-item)');
            newsElements.forEach(element => {
                if (observerRef.current) {
                    observerRef.current.observe(element);
                }
            });
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [displayedNews]);

    const handleClick = useCallback(async (newsId, slug, event) => {
        event.preventDefault();
        event.stopPropagation();
        const originalId = newsId.split('-repeat-')[0];
        await handleAdClick(originalId, slug);
    }, []);

    const renderNewsItem = useCallback((news, isLarge = false) => {
        if (!news._id) return null;

        // Получаем оригинальный ID без суффикса повтора
        const originalId = news._id.split('-repeat-')[0];
        
        // Проверяем, не является ли это топовой новостью И не является ли это повтором
        // Повторы определяем по наличию '-repeat-' в id
        const isRepeat = news._id.includes('-repeat-');
        
        // Если это оригинальная (не повторная) новость и совпадает с верхней - не показываем
        if (!isRepeat && topNewsId && originalId === topNewsId) {
            return null;
        }

        const redirectUrl = `/out/news/${news.slug}`;

        return (
            <div 
                className={`item item-t ${isLarge ? 'item-lg' : 'item-sm'} news-item`}
                key={news._id}
                data-news-id={news._id}
                data-title={news.title}
            >
                <div className="teaser">
                    <a 
                        href={redirectUrl}
                        onClick={(e) => handleClick(news._id, news.slug, e)}
                        className="dynamic-load"
                        rel="noopener noreferrer"
                    >
                        <div className="item-overlay"></div>
                        <div className="item-image">
                            <img 
                                src={news.image_url} 
                                alt={news.title}
                                loading="lazy"
                            />
                        </div>
                        <div className="item-title" data-slug={news.slug} data-id={news._id}>
                            {news.title}
                        </div>
                    </a>
                </div>
            </div>
        );
    }, [topNewsId]);

    if (!displayedNews.length) return null;

    return (
        <>
            {displayedNews.map((news, index) => renderNewsItem(news, (index % 6 >= 4)))}
            {hasMore && <div ref={loadingRef} style={{ height: '20px', margin: '10px 0' }} />}
        </>
    );
};

export default RelatedNewsBlock; 