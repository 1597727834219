import { api } from './api';
import { FILTER_CONSTANTS } from './constants';
import { getStatusForApi } from './statusHelpers';
import { NEWS_STATUS_CHANGE_EVENT } from '../components/Sidebar/AdminSidebar';

export const executeBulkAction = async ({
    action,
    selectedIds,
    updateAllNews,
    updateStats,
    fetchNewsData,
    clearSelection,
    onError,
    newsItems = []  // Добавляем параметр для информации о новостях
}) => {
    try {
        switch (action) {
            case FILTER_CONSTANTS.BULK_ACTIONS.ACTIVATE:
            case FILTER_CONSTANTS.BULK_ACTIONS.DEACTIVATE:
                const isActive = action === FILTER_CONSTANTS.BULK_ACTIONS.ACTIVATE;
                await Promise.all(selectedIds.map(id => 
                    api.put(`/admin/news/${id}/status`, { isActive })
                ));
                updateAllNews(prevNews => 
                    prevNews.map(item => 
                        selectedIds.includes(item._id) 
                            ? { ...item, isActive } 
                            : item
                    )
                );
                
                // Генерируем событие для обновления статистики в сайдбаре
                window.dispatchEvent(new CustomEvent(NEWS_STATUS_CHANGE_EVENT, {
                    detail: { 
                        bulkUpdate: true,
                        count: selectedIds.length,
                        isActive
                    }
                }));
                break;

            case 'delete':
                if (window.confirm('Вы уверены, что хотите удалить выбранные новости?')) {
                    await Promise.all(selectedIds.map(id => api.delete(`/admin/news/${id}`)));
                    updateAllNews(prevNews => 
                        prevNews.filter(item => !selectedIds.includes(item._id))
                    );
                    
                    // При удалении также обновляем статистику
                    window.dispatchEvent(new CustomEvent(NEWS_STATUS_CHANGE_EVENT));
                }
                break;

            case 'changeUrl':
                const newUrl = prompt('Введите новый URL:');
                if (newUrl) {
                    await Promise.all(selectedIds.map(id => 
                        api.put(`/admin/news/${id}`, { sourceUrl: newUrl })
                    ));
                }
                break;
        }

        await fetchNewsData();
        clearSelection();
    } catch (err) {
        console.error('Error performing bulk action:', err);
        onError('Ошибка при выполнении массового действия');
    }
}; 