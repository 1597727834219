import React, { useRef, useEffect, useCallback } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { debounce } from 'lodash';
import { newsAPI } from '../../../public/utils/api';
import '../../../styles/NewsTableFilters.css';
import { getCurrentFilters } from '../../utils/filters';
import { getStatusFromBoolean } from '../../utils/statusHelpers';

// Импортируем новые компоненты
import ActionButton from './TableComponents/Filters/ActionButton';
import BulkActionsButton from './TableComponents/Filters/BulkActionsButton';
import StatusFilter from './TableComponents/Filters/StatusFilter';
import LanguageFilter from './TableComponents/Filters/LanguageFilter';

const NewsTableFilters = ({
    selectedItems,
    showBulkActionsMenu,
    setShowBulkActionsMenu,
    onBulkAction,
    searchQuery,
    setSearchQuery,
    statusFilter,
    setStatusFilter,
    showStatusMenu,
    setShowStatusMenu,
    languageFilter,
    setLanguageFilter,
    showLanguageMenu,
    setShowLanguageMenu,
    handleResetFilters,
    updateStats,
    isUpdatingStats,
    onFilterChange,
    onStatsUpdated,
    isLoading,
    setIsLoading,
    rowsPerPage
}) => {
    const statusMenuRef = useRef(null);
    const languageMenuRef = useRef(null);
    const bulkActionsMenuRef = useRef(null);

    const handleSearch = useCallback((value) => {
        setSearchQuery(value);
        onFilterChange({ search: value });
    }, [setSearchQuery, onFilterChange]);

    const debouncedSearch = useRef(
        debounce((value) => {
            handleSearch(value);
        }, 300)
    ).current;

    const handleSearchInputChange = useCallback((e) => {
        const value = e.target.value;
        setSearchQuery(value);
        debouncedSearch(value);
    }, [setSearchQuery]);

    const handleStatusChange = useCallback((newStatus) => {
        console.log('Status change:', newStatus);
        setStatusFilter(newStatus);
        setShowStatusMenu(false);
        onFilterChange({ status: newStatus });
    }, [setStatusFilter, setShowStatusMenu, onFilterChange]);

    const handleLanguageChange = useCallback((newLanguage) => {
        setLanguageFilter(newLanguage);
        setShowLanguageMenu(false);
        onFilterChange({ language: newLanguage });
    }, [setLanguageFilter, setShowLanguageMenu, onFilterChange]);

    const handleReset = useCallback(() => {
        setSearchQuery('');
        setStatusFilter('all');
        setLanguageFilter('all');
        handleResetFilters();
    }, [setSearchQuery, setStatusFilter, setLanguageFilter, handleResetFilters]);

    const handleUpdateStats = async () => {
        try {
            if (typeof setIsLoading === 'function') {
                setIsLoading(true);
            }
            await updateStats();

            // Получаем текущие фильтры
            const currentFilters = getCurrentFilters(
                searchQuery,
                statusFilter,
                languageFilter,
                1,
                rowsPerPage
            );

            console.log('Updating with filters:', currentFilters);

            const response = await newsAPI.getFilteredNews(currentFilters);

            if (response && response.news) {
                if (typeof onStatsUpdated === 'function') {
                    // Передаем текущие фильтры
                    onStatsUpdated(response.news, {
                        search: searchQuery,
                        status: statusFilter,
                        language: languageFilter
                    });
                }
            }
        } catch (error) {
            if (error.response?.status === 401) {
                window.location.href = '/admin';
                return;
            }
            console.error('Ошибка при обновлении статистики:', error);
        } finally {
            if (typeof setIsLoading === 'function') {
                setIsLoading(false);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            const statusMenu = statusMenuRef.current;
            const languageMenu = languageMenuRef.current;
            const bulkActionsMenu = bulkActionsMenuRef.current;

            if (statusMenu && !statusMenu.contains(event.target)) {
                setShowStatusMenu(false);
            }
            if (languageMenu && !languageMenu.contains(event.target)) {
                setShowLanguageMenu(false);
            }
            if (bulkActionsMenu && !bulkActionsMenu.contains(event.target)) {
                setShowBulkActionsMenu(false);
            }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowStatusMenu, setShowLanguageMenu, setShowBulkActionsMenu]);

    return (
        <div className="filters-container">
            {isLoading && <div className="loading-overlay" />}
            <div className="news-table-filters">
                <div className="toolbar-left">
                    <BulkActionsButton
                        selectedItems={selectedItems}
                        showBulkActionsMenu={showBulkActionsMenu}
                        setShowBulkActionsMenu={setShowBulkActionsMenu}
                        onBulkAction={onBulkAction}
                    />
                    <div className="search-container">
                        <SearchIcon className="search-icon" />
                        <input
                            type="text"
                            placeholder="Введите URL, ID или заголовок объявления"
                            value={searchQuery || ''}
                            onChange={handleSearchInputChange}
                            className="search-input"
                        />
                    </div>
                    <StatusFilter
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        showStatusMenu={showStatusMenu}
                        setShowStatusMenu={setShowStatusMenu}
                        handleStatusChange={handleStatusChange}
                    />
                    <LanguageFilter
                        languageFilter={languageFilter}
                        setLanguageFilter={setLanguageFilter}
                        showLanguageMenu={showLanguageMenu}
                        setShowLanguageMenu={setShowLanguageMenu}
                        handleLanguageChange={handleLanguageChange}
                    />
                    {(searchQuery || statusFilter !== 'all' || languageFilter !== 'all') && (
                        <ActionButton
                            onClick={handleReset}
                            className="reset-filter-button"
                            label="Сбросить фильтры"
                            icon={<span className="close-icon">×</span>}
                        />
                    )}
                </div>
                <ActionButton
                    onClick={handleUpdateStats}
                    disabled={isUpdatingStats}
                    className="refresh-button"
                    icon={<RefreshIcon className={isUpdatingStats ? 'spinning' : ''} />}
                    label="Обновить статистику"
                />
            </div>
        </div>
    );
};

export default NewsTableFilters;
