import { FILTER_CONSTANTS } from '../utils/constants';

// Преобразование статуса для API
export const getStatusForApi = (statusFilter) => {
    if (statusFilter === FILTER_CONSTANTS.STATUSES.ALL) return undefined;
    return statusFilter === FILTER_CONSTANTS.STATUSES.ACTIVE;
};

// Преобразование булева значения в строковый статус
export const getStatusFromBoolean = (isActive) => {
    return isActive ? FILTER_CONSTANTS.STATUSES.ACTIVE : FILTER_CONSTANTS.STATUSES.INACTIVE;
};

// Проверка соответствия статуса фильтру
export const matchesStatusFilter = (item, statusFilter) => {
    if (statusFilter === FILTER_CONSTANTS.STATUSES.ALL) return true;
    return statusFilter === FILTER_CONSTANTS.STATUSES.ACTIVE ? item.isActive : !item.isActive;
}; 