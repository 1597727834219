import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './public/pages/Home';
import Login from './admin/pages/Login';
import Dashboard from './admin/pages/Dashboard';
import NewsPage from './admin/pages/NewsPage';
import NewsForm from './admin/pages/News/NewsForm';
import TopNews from './public/pages/TopNews';
import News from './public/pages/News';
import NewsDetail from './public/pages/NewsDetail';
import NewsRedirect from './public/utils/NewsRedirect';
import NewsStats from './admin/pages/News/NewsStats';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import Tools from './admin/pages/Tools/Tools';
import CodeInjector from './public/components/CodeInjector';

const ProtectedRoute = ({ children }) => {
    const token = localStorage.getItem('token');
    if (!token) {
        return <Navigate to="/admin" replace />;
    }
    return children;
};

function App() {
    return (
        <HelmetProvider>
            <Helmet>
                <meta name="description" content="Новостной агрегатор LineNews" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="LineNews" />
            </Helmet>
            <Router>
                <Routes>
                    <Route path="/" element={<><CodeInjector /><Home /></>} />
                    <Route path="/news/top.html" element={<><CodeInjector /><TopNews /></>} />
                    <Route path="/news" element={<><CodeInjector /><News /></>} />
                    <Route path="/news/:slug" element={<><CodeInjector /><NewsDetail /></>} />
                    
                    <Route path="/forward/news/:slug" element={<><CodeInjector /><NewsRedirect /></>} />
                    
                    <Route path="/out/news/:slug" element={<><CodeInjector /><NewsRedirect isAd /></>} />

                    <Route path="/admin" element={<Login />} />
                    <Route
                        path="/admin/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/news"
                        element={
                            <ProtectedRoute>
                                <NewsPage />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/news/create"
                        element={
                            <ProtectedRoute>
                                <NewsForm />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/news/edit/:id"
                        element={
                            <ProtectedRoute>
                                <NewsForm isEditing />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/news/:id/stats"
                        element={
                            <ProtectedRoute>
                                <NewsStats />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/admin/tools"
                        element={
                            <ProtectedRoute>
                                <Tools />
                            </ProtectedRoute>
                        }
                    />
                    
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Router>
        </HelmetProvider>
    );
}

export default App;