import { newsAPI } from './api';

// Регистрация просмотра
export const registerView = async (newsId) => {
    if (!newsId) return;
    
    try {
        await newsAPI.registerView(newsId);
    } catch (error) {
        console.error('Error registering view:', error);
    }
};

// Регистрация клика
export const registerClick = async (newsId) => {
    if (!newsId) return;
    
    try {
        await newsAPI.registerClick(newsId);
    } catch (error) {
        console.error('Error registering click:', error);
    }
};

// Регистрация просмотра рекламы
export const registerAdView = async (newsId) => {
    if (!newsId) return;
    
    try {
        await newsAPI.registerAdView(newsId);
    } catch (error) {
        console.error('Error registering ad view:', error);
    }
};

// Регистрация клика по рекламе
export const registerAdClick = async (newsId) => {
    if (!newsId) return;
    
    try {
        await newsAPI.registerAdClick(newsId);
    } catch (error) {
        console.error('Error registering ad click:', error);
    }
};

// Обработка клика с редиректом
export const handleNewsClick = async (newsId, slug, sourceUrl) => {
    if (!newsId || !slug) return false;

    try {
        await registerClick(newsId);
        const response = await newsAPI.getRedirectUrl(slug);
        
        if (response.data?.redirectUrl) {
            window.open(response.data.redirectUrl, '_blank', 'noopener,noreferrer');
            return true;
        }
    } catch (error) {
        if (sourceUrl) {
            let url = sourceUrl;
            if (!url.startsWith('http')) {
                url = 'https://' + url;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
            return true;
        }
    }
    return false;
};

// Обработка клика по рекламе с редиректом
export const handleAdClick = async (newsId, slug, sourceUrl) => {
    if (!newsId || !slug) return false;

    try {
        await registerAdClick(newsId);
        const response = await newsAPI.getAdRedirectUrl(slug);
        
        if (response.data?.redirectUrl) {
            window.open(response.data.redirectUrl, '_blank', 'noopener,noreferrer');
            return true;
        }
    } catch (error) {
        if (sourceUrl) {
            let url = sourceUrl;
            if (!url.startsWith('http')) {
                url = 'https://' + url;
            }
            window.open(url, '_blank', 'noopener,noreferrer');
            return true;
        }
    }
    return false;
}; 