import React, { useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import BarChartIcon from '@mui/icons-material/BarChart';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import '../../../../../styles/NewsTableRow.css';
import { NEWS_STATUS_CHANGE_EVENT } from '../../../Sidebar/AdminSidebar';

const ActionButtons = ({
    item,
    showMenu,
    setShowMenu,
    handleToggleActive,
    handleDelete,
    getLanguageName
}) => {
    const navigate = useNavigate();
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowMenu]);

    const handleOpenUrl = (e) => {
        e.stopPropagation();
        if (item.slug) {
            window.open(`/news/${item.slug}`, '_blank');
        }
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        if (window.confirm('Вы уверены, что хотите удалить эту новость?')) {
            handleDelete(item._id);
        }
    };

    const handleStatsClick = (e) => {
        e.stopPropagation();
        navigate(`/admin/news/${item._id}/stats`);
    };

    return (
        <div className="action-column">
            <div className="action-row">
                <div className="toggle-switch">
                    <input
                        type="checkbox"
                        checked={item.isActive}
                        onChange={() => {
                            handleToggleActive(item._id, item.isActive);
                            // Генерируем событие для обновления статистики
                            window.dispatchEvent(new CustomEvent(NEWS_STATUS_CHANGE_EVENT, {
                                detail: { 
                                    newsId: item._id,
                                    language: item.language,
                                    isActive: !item.isActive 
                                }
                            }));
                        }}
                    />
                    <span className="toggle-slider"></span>
                </div>
                <div className="info-row">
                    <span className="info-label">ID:</span>
                    <span className="info-value">{item.orderNumber || 'Не задано'}</span>
                </div>
            </div>

            <div className="info-row">
                <span>Создано: {new Date(item.createdAt).toLocaleDateString('ru-RU')}</span>
                <span>|</span>
                <span>Язык: {getLanguageName(item.language)}</span>
            </div>

            <div className="action-row">
                <button 
                    onClick={handleStatsClick}
                    className="action-link"
                >
                    <BarChartIcon style={{ fontSize: 16 }} />
                    <span>Статистика</span>
                </button>
                
                <button
                    onClick={() => navigate(`/admin/news/edit/${item._id}`)}
                    className="action-link"
                >
                    <EditIcon style={{ fontSize: 16 }} />
                    <span>Редактировать</span>
                </button>

                <div className="menu-wrapper" ref={menuRef}>
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            setShowMenu(showMenu === item._id ? null : item._id);
                        }}
                        className="menu-button"
                    >
                        •••
                    </button>
                    
                    {showMenu === item._id && (
                        <div 
                            className="dropdown-menu" 
                            onClick={(e) => e.stopPropagation()}
                            onMouseDown={(e) => e.stopPropagation()}
                        >
                            <button
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenUrl(e);
                                }}
                                className="menu-item"
                            >
                                <OpenInNewIcon style={{ fontSize: 16 }} />
                                <span>Перейти к объявлению</span>
                            </button>
                            <div className="menu-divider" />
                            <button
                                onMouseDown={(e) => e.stopPropagation()}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteClick(e);
                                }}
                                className="menu-item delete"
                            >
                                <DeleteIcon style={{ fontSize: 16 }} />
                                <span>Удалить</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ActionButtons;