import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/Layout';
import { settingsAPI } from '../../utils/api';
import '../../../styles/tools.css';

const Tools = () => {
    const [settings, setSettings] = useState({
        headerCode: '',
        footerCode: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState({ type: '', text: '' });

    useEffect(() => {
        fetchSettings();
    }, []);

    const fetchSettings = async () => {
        try {
            const response = await settingsAPI.getToolsSettings();
            setSettings(response);
        } catch (error) {
            setMessage({
                type: 'error',
                text: 'Ошибка при загрузке настроек'
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await settingsAPI.saveToolsSettings(settings);
            setMessage({
                type: 'success',
                text: 'Настройки успешно сохранены'
            });
        } catch (error) {
            setMessage({
                type: 'error',
                text: 'Ошибка при сохранении настроек'
            });
        }
        setIsLoading(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSettings(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <AdminLayout>
            <div className="tools-container">
                <div className="tools-header">
                    <h1 className="tools-title">Инструменты</h1>
                </div>

                {message.text && (
                    <div className={`message ${message.type}`}>
                        {message.text}
                    </div>
                )}

                <div className="tools-content">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="headerCode">
                                Код для вставки в head
                                <span className="help-text">
                                    (например, метатеги или скрипты аналитики)
                                </span>
                            </label>
                            <textarea
                                id="headerCode"
                                name="headerCode"
                                value={settings.headerCode}
                                onChange={handleChange}
                                rows="6"
                                placeholder="<!-- Вставьте код для head -->"
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="footerCode">
                                Код для вставки в footer
                                <span className="help-text">
                                    (например, код счетчиков)
                                </span>
                            </label>
                            <textarea
                                id="footerCode"
                                name="footerCode"
                                value={settings.footerCode}
                                onChange={handleChange}
                                rows="6"
                                placeholder="<!-- Вставьте код для footer -->"
                            />
                        </div>

                        <div className="form-actions">
                            <button 
                                type="submit" 
                                className="btn-primary"
                                disabled={isLoading}
                            >
                                {isLoading ? 'Сохранение...' : 'Сохранить'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Tools; 