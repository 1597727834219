import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;
if (!BASE_URL) {
    throw new Error('REACT_APP_API_URL не установлен в переменных окружения');
}
// Добавляем BASE_URL без /api для изображений
export const BASE_DOMAIN = BASE_URL.replace('/api', '');

export const api = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Accept': 'application/json'
    }
});

// Добавляем методы для аутентификации
export const authAPI = {
    login: (credentials) => api.post('/admin/auth/login', credentials),  // Изменили путь с /auth/login на /admin/auth/login
    logout: () => api.post('/admin/auth/logout')  // Если есть логаут
};

// Добавляем новые методы для работы с настройками
export const settingsAPI = {
    // Получение настроек инструментов
    getToolsSettings: async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.get('/admin/settings/tools', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    // Сохранение настроек инструментов
    saveToolsSettings: async (settings) => {
        try {
            const token = localStorage.getItem('token');
            const response = await api.post('/admin/settings/tools', settings, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
};

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            if (config.data instanceof FormData) {
                config.headers = {
                    ...config.headers,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data'
                };
            } else {
                config.headers = {
                    ...config.headers,
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                };
            }
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // Проверяем, что это не запрос на логин
        if (error.response?.status === 401 && !error.config.url.includes('/auth/login')) {
            localStorage.removeItem('token');
            window.location.href = '/admin';  // Изменили на /admin вместо /admin/login
            return Promise.reject({ message: 'Сессия истекла. Пожалуйста, войдите снова.' });
        }

        const errorResponse = {
            message: error.response?.data?.message || 'Произошла ошибка при выполнении запроса',
            errors: error.response?.data?.errors,
            status: error.response?.status
        };

        return Promise.reject(errorResponse);
    }
);

export default api;