import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { authAPI } from '../utils/api';

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        console.log('API URL:', process.env.REACT_APP_API_URL);

        try {
            const response = await authAPI.login({ username, password });
            if (response.data.token) {
                localStorage.setItem('token', response.data.token);
                navigate('/admin/dashboard');
            }
        } catch (error) {
            console.error('API Error: ', error);
            setError(error.response?.data?.message || 'Ошибка при входе');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="login-container" style={{
            minHeight: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'linear-gradient(135deg, #1e3c72 0%, #2a5298 100%)',
            fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
        }}>
            <div className="login-card" style={{
                width: '100%',
                maxWidth: '400px',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: '15px',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
                padding: '40px',
                margin: '20px',
                transition: 'transform 0.3s ease',
                animation: 'fadeIn 0.5s ease-out'
            }}>
                <div className="login-header" style={{
                    textAlign: 'center',
                    marginBottom: '40px'
                }}>
                    <h1 style={{
                        color: '#1e3c72',
                        fontSize: '28px',
                        marginBottom: '10px',
                        fontWeight: '600'
                    }}>
                        Панель управления
                    </h1>
                    <p style={{
                        color: '#666',
                        fontSize: '14px'
                    }}>
                        Введите свои учетные данные для входа
                    </p>
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="form-group" style={{
                        marginBottom: '25px'
                    }}>
                        <label style={{
                            display: 'block',
                            marginBottom: '8px',
                            color: '#1e3c72',
                            fontSize: '14px',
                            fontWeight: '500'
                        }}>
                            Имя пользователя
                        </label>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '12px 16px',
                                border: '2px solid #e1e1e1',
                                borderRadius: '8px',
                                fontSize: '16px',
                                transition: 'border-color 0.3s ease',
                                outline: 'none'
                            }}
                            onFocus={(e) => e.target.style.borderColor = '#2a5298'}
                            onBlur={(e) => e.target.style.borderColor = '#e1e1e1'}
                            required
                        />
                    </div>

                    <div className="form-group" style={{
                        marginBottom: '25px'
                    }}>
                        <label style={{
                            display: 'block',
                            marginBottom: '8px',
                            color: '#1e3c72',
                            fontSize: '14px',
                            fontWeight: '500'
                        }}>
                            Пароль
                        </label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            style={{
                                width: '100%',
                                padding: '12px 16px',
                                border: '2px solid #e1e1e1',
                                borderRadius: '8px',
                                fontSize: '16px',
                                transition: 'border-color 0.3s ease',
                                outline: 'none'
                            }}
                            onFocus={(e) => e.target.style.borderColor = '#2a5298'}
                            onBlur={(e) => e.target.style.borderColor = '#e1e1e1'}
                            required
                        />
                    </div>

                    {error && (
                        <div style={{
                            padding: '12px',
                            backgroundColor: '#fff5f5',
                            color: '#dc2626',
                            borderRadius: '8px',
                            fontSize: '14px',
                            textAlign: 'center',
                            marginBottom: '20px',
                            border: '1px solid #fee2e2'
                        }}>
                            {error}
                        </div>
                    )}

                    <button
                        type="submit"
                        disabled={isLoading}
                        style={{
                            width: '100%',
                            padding: '14px',
                            backgroundColor: '#1e3c72',
                            color: 'white',
                            border: 'none',
                            borderRadius: '8px',
                            fontSize: '16px',
                            fontWeight: '500',
                            cursor: 'pointer',
                            transition: 'all 0.3s ease',
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                        onMouseOver={(e) => e.target.style.backgroundColor = '#2a5298'}
                        onMouseOut={(e) => e.target.style.backgroundColor = '#1e3c72'}
                    >
                        {isLoading ? (
                            <span style={{
                                display: 'inline-block',
                                animation: 'spin 1s linear infinite'
                            }}>
                                ⭮
                            </span>
                        ) : 'Войти'}
                    </button>
                </form>
            </div>

            <style>
                {`
                    @keyframes fadeIn {
                        from {
                            opacity: 0;
                            transform: translateY(-20px);
                        }
                        to {
                            opacity: 1;
                            transform: translateY(0);
                        }
                    }

                    @keyframes spin {
                        from {
                            transform: rotate(0deg);
                        }
                        to {
                            transform: rotate(360deg);
                        }
                    }

                    .login-card:hover {
                        transform: translateY(-5px);
                    }

                    input:focus {
                        box-shadow: 0 0 0 3px rgba(42, 82, 152, 0.1);
                    }

                    button:disabled {
                        background-color: #93a8d5 !important;
                        cursor: not-allowed;
                    }
                `}
            </style>
        </div>
    );
};

export default Login;