import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import BarChartIcon from '@mui/icons-material/BarChart';
import StatsOverview from '../../components/Stats/StatsOverview';
import DateRangePicker from '../../components/DateRangePicker/DateRangePicker';
import { api, BASE_DOMAIN } from '../../utils/api';
import { format, subDays, startOfDay } from 'date-fns';
import '../../components/Toggle/Toggle.css';
import { debounce } from 'lodash';
import NewsTableRow from '../../components/News/NewsTableRow';
import NewsTableHeaderColumns from '../../components/News/TableComponents/Row/NewsTableHeaderColumns';
import NewsTableFilters from '../../components/News/NewsTableFilters';
import NewsTablePagination from '../../components/News/NewsTablePagination';
import axios from 'axios';
import NewsHeader from './components/NewsHeader';
import { getLanguageName } from '../../utils/constants';
import { executeBulkAction } from '../../utils/bulkActions';
import { getStatsByLanguage, calculateTableTotals } from '../../utils/statsCalculations';
import { filterNews, getFilterParams, getCurrentFilters } from '../../utils/filters';
import { sortNews } from '../../utils/sortHelpers';
import { getStatusFromBoolean } from '../../utils/statusHelpers';

const NewsList = () => {
    const navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedItems, setSelectedItems] = useState(new Set());
    const [showBulkActions, setShowBulkActions] = useState(false);
    const [showBulkActionsMenu, setShowBulkActionsMenu] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [dateRange, setDateRange] = useState({
        startDate: startOfDay(subDays(new Date(), 6)),
        endDate: startOfDay(new Date())
    });
    const [statusFilter, setStatusFilter] = useState('all');
    const [showStatusMenu, setShowStatusMenu] = useState(false);
    const [languageFilter, setLanguageFilter] = useState('all');
    const [showLanguageMenu, setShowLanguageMenu] = useState(false);
    const [isUpdatingStats, setIsUpdatingStats] = useState(false);
    const [showMenu, setShowMenu] = useState(null);
    const [sortField, setSortField] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [allNews, setAllNews] = useState([]);
    const [newsStats, setNewsStats] = useState({
        ru: { active: 0, inactive: 0 },
        pl: { active: 0, inactive: 0 },
        ro: { active: 0, inactive: 0 }
    });
    const [selectedDate, setSelectedDate] = useState(null);
    const [historicalStats, setHistoricalStats] = useState([]);
    const [statsUpdateCounter, setStatsUpdateCounter] = useState(0);
    const [tableTotals, setTableTotals] = useState({
        views: 0,
        clicks: 0,
        adsViews: 0,
        adsClicks: 0
    });
    const [forceUpdate, setForceUpdate] = useState(false);

    const menuRef = useRef(null);

    const getFilteredNews = useCallback(() => {
        return filterNews(news, { searchQuery, statusFilter, languageFilter });
    }, [news, searchQuery, statusFilter, languageFilter]);

    const getSortedAndPaginatedNews = useCallback(() => {
        const filtered = getFilteredNews();
        return filtered;
    }, [getFilteredNews]);

    const fetchNewsData = useCallback(async (page, limit) => {
        if (isLoading) return;
        
        setIsLoading(true);
        try {
            const params = getCurrentFilters(
                searchQuery,
                statusFilter,
                languageFilter,
                page,
                limit
            );

            const response = await api.get('/admin/news', { params });
            
            if (response.data) {
                // Применяем текущую сортировку к загруженным данным
                const newsData = response.data.news || [];
                const sortedNews = sortField ? sortNews(newsData, sortField, sortDirection) : newsData;
                
                setNews(sortedNews);
                setTotalCount(response.data.totalNews || 0);
                setCurrentPage(response.data.currentPage || 1);
                setTableTotals(calculateTableTotals(newsData));
            }
        } catch (err) {
            setError('Ошибка при загрузке новостей');
        } finally {
            setIsLoading(false);
            setLoading(false);
        }
    }, [isLoading, searchQuery, statusFilter, languageFilter, sortField, sortDirection]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }

        // Загружаем отфильтрованные новости
        fetchNewsData(1, rowsPerPage);

        // Загружаем все новости для статистики
        api.get('/admin/news', { params: { page: 1, limit: 1000 } })
            .then(response => {
                if (response.data) {
                    const allNewsData = response.data.news || [];
                    setAllNews(allNewsData);
                    // Инициализируем статистику
                    const initialStats = getStatsByLanguage(allNewsData);
                    setNewsStats(initialStats);
                    // Рассчитываем итоги с учетом выбранной даты
                    setTableTotals(calculateTableTotals(allNewsData, historicalStats, selectedDate));
                }
            })
            .catch(err => {
                // Error handling without console.error
            });
    }, [selectedDate, historicalStats]); // Добавляем зависимости

    useEffect(() => {
        setShowBulkActions(selectedItems.size > 0);
    }, [selectedItems]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelectItem = (id) => {
        const newSelected = new Set(selectedItems);
        if (newSelected.has(id)) {
            newSelected.delete(id);
        } else {
            newSelected.add(id);
        }
        setSelectedItems(newSelected);
    };

    const handleSelectAll = (event) => {
        setSelectedItems(event.target.checked ? new Set(news.map(item => item._id)) : new Set());
    };

    const handleToggleActive = async (id, currentStatus) => {
        try {
            await api.put(`/admin/news/${id}/status`, { isActive: !currentStatus });
            
            // Обновляем локальное состояние новостей
            const updateNewsState = (prevNews) => {
                const updatedNews = prevNews.map(item => {
                    if (item._id === id) {
                        return { ...item, isActive: !currentStatus };
                    }
                    return item;
                });
                // Применяем текущую сортировку к обновленным данным
                return sortField ? sortNews(updatedNews, sortField, sortDirection) : updatedNews;
            };

            setNews(updateNewsState);
            setAllNews(prevAllNews => {
                const updatedNews = updateNewsState(prevAllNews);
                const newStats = getStatsByLanguage(updatedNews);
                setNewsStats(newStats);
                return updatedNews;
            });
            
            // Обновляем данные с сервера с учетом текущих фильтров
            fetchNewsData(currentPage, rowsPerPage);
            
        } catch (err) {
            setError('Ошибка при изменении статуса новости');
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Вы уверены, что хотите удалить эту новость?')) {
            try {
                await api.delete(`/admin/news/${id}`);
                fetchNewsData();
            } catch (err) {
                // Error handling without console.error
            }
        }
    };

    const handleBulkAction = async (action) => {
        const selectedIds = Array.from(selectedItems);
        
        await executeBulkAction({
            action,
            selectedIds,
            updateAllNews: setAllNews,
            updateStats: (news) => {
                const newStats = getStatsByLanguage(news);
                setNewsStats(newStats);
                return news;
            },
            fetchNewsData: () => fetchNewsData(currentPage, rowsPerPage),
            clearSelection: () => {
                setSelectedItems(new Set());
                setShowBulkActionsMenu(false);
            },
            onError: setError,
            newsItems: allNews.filter(item => selectedItems.has(item._id))
        });
    };

    const handleDateRangeChange = (newRange) => {
        if (newRange.startDate && newRange.endDate) {
            setDateRange({
                startDate: startOfDay(new Date(newRange.startDate)),
                endDate: startOfDay(new Date(newRange.endDate))
            });
        }
    };

    const handleSort = (field) => {
        const newDirection = sortField === field
            ? (sortDirection === 'asc' ? 'desc' : 'asc')
            : 'asc';
            
        setSortField(field);
        setSortDirection(newDirection);
        setNews(prevNews => sortNews(prevNews, field, newDirection));
    };

    const handleResetFilters = useCallback(() => {
        setSearchQuery('');
        setStatusFilter('all');
        setLanguageFilter('all');
        setCurrentPage(1);
        
        const params = {
            page: 1,
            limit: rowsPerPage
        };
        
        setIsLoading(true);
        api.get('/admin/news', { params })
            .then(response => {
                if (response.data) {
                    setNews(response.data.news || []);
                    setTotalCount(response.data.totalNews || 0);
                    setCurrentPage(response.data.currentPage || 1);
                }
            })
            .catch(err => {
                setError('Ошибка при сбросе фильтров');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [rowsPerPage]);

    const updateStats = async () => {
        try {
            setIsUpdatingStats(true);
            // Получаем все ID новостей из allNews, а не только из отфильтрованных news
            const newsIds = allNews.map(item => item._id);
            const response = await api.get('/admin/news/stats', {
                params: { ids: newsIds.join(',') }
            });
            
            if (response.data && response.data.news) {
                // Обновляем и news, и allNews
                const updatedStats = response.data.news;
                
                setNews(prevNews => prevNews.map(item => {
                    const updatedItem = updatedStats.find(n => n._id === item._id);
                    return updatedItem ? {
                        ...item,
                        stats: updatedItem.stats || item.stats,
                        mainStats: updatedItem.mainStats || item.mainStats
                    } : item;
                }));

                // Обновляем также allNews
                setAllNews(prevAllNews => prevAllNews.map(item => {
                    const updatedItem = updatedStats.find(n => n._id === item._id);
                    return updatedItem ? {
                        ...item,
                        stats: updatedItem.stats || item.stats,
                        mainStats: updatedItem.mainStats || item.mainStats
                    } : item;
                }));
            }
        } catch (err) {
            console.error('Ошибка при обновлении статистики:', err);
        } finally {
            setIsUpdatingStats(false);
        }
    };

    const stats = getStatsByLanguage(allNews);

    const avgCtr = tableTotals.views ? ((tableTotals.clicks / tableTotals.views) * 100).toFixed(2) : '0.00';
    const avgAdsCtr = tableTotals.adsViews ? ((tableTotals.adsClicks / tableTotals.adsViews) * 100).toFixed(2) : '0.00';

    const handleFilterChange = useCallback((newFilters = {}) => {
        setCurrentPage(1);
        setIsLoading(true);

        const params = getCurrentFilters(
            newFilters.search !== undefined ? newFilters.search : searchQuery,
            newFilters.status !== undefined ? newFilters.status : statusFilter,
            newFilters.language !== undefined ? newFilters.language : languageFilter,
            1,
            rowsPerPage
        );

        api.get('/admin/news', { params })
            .then(response => {
                if (response.data) {
                    // Применяем текущую сортировку к отфильтрованным данным
                    const newsData = response.data.news || [];
                    const sortedNews = sortField ? sortNews(newsData, sortField, sortDirection) : newsData;
                    
                    setNews(sortedNews);
                    setTotalCount(response.data.totalNews || 0);
                    setCurrentPage(response.data.currentPage || 1);
                }
            })
            .catch(err => {
                setError('Ошибка при применении фильтров');
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, [searchQuery, statusFilter, languageFilter, rowsPerPage, sortField, sortDirection]);

    const handleStatsChange = (stats, date) => {
        setSelectedDate(date);
        setHistoricalStats(stats);
        // Пересчитываем итоги с новыми данными
        setTableTotals(calculateTableTotals(news, stats, date));
    };

    useEffect(() => {
        console.log('historicalStats updated:', historicalStats);
    }, [historicalStats]);

    const handleStatsUpdated = (updatedNews, filters) => {
        // Применяем фильтры к обновленным данным
        const filteredNews = filterNews(updatedNews, {
            searchQuery: filters.search || '',
            statusFilter: statusFilter,
            languageFilter: filters.language || 'all'
        });

        // Применяем текущую сортировку к отфильтрованным данным
        const sortedNews = sortField ? sortNews(filteredNews, sortField, sortDirection) : filteredNews;
        
        setNews(sortedNews);
        setForceUpdate(prev => !prev);
    };

    if (loading) {
        return <div className="news-loading-state">Загрузка...</div>;
    }

    if (error) {
        return <div className="news-error-state">{error}</div>;
    }

    return (
        <div className="news-container">
            <div className="news-content">
                <NewsHeader 
                    onStatsChange={handleStatsChange}
                    newsStats={newsStats}
                />

                <div className="news-main-section">
                    <NewsTableFilters
                        selectedItems={selectedItems}
                        showBulkActionsMenu={showBulkActionsMenu}
                        setShowBulkActionsMenu={setShowBulkActionsMenu}
                        onBulkAction={handleBulkAction}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        statusFilter={statusFilter}
                        setStatusFilter={setStatusFilter}
                        showStatusMenu={showStatusMenu}
                        setShowStatusMenu={setShowStatusMenu}
                        languageFilter={languageFilter}
                        setLanguageFilter={setLanguageFilter}
                        showLanguageMenu={showLanguageMenu}
                        setShowLanguageMenu={setShowLanguageMenu}
                        handleResetFilters={handleResetFilters}
                        updateStats={updateStats}
                        isUpdatingStats={isUpdatingStats}
                        onFilterChange={handleFilterChange}
                        onStatsUpdated={handleStatsUpdated}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        rowsPerPage={rowsPerPage}
                    />

                    {news.length === 0 ? (
                        <div className="news-empty-state">
                            {searchQuery ? 'Ничего не найдено' : 'Новости пока отсутствуют'}
                        </div>
                    ) : (
                        <div className="news-table-container">
                            <table className="news-table">
                                <NewsTableHeaderColumns 
                                    handleSelectAll={handleSelectAll}
                                    filteredAndSortedNews={news}
                                    selectedItems={selectedItems}
                                    handleSort={handleSort}
                                    sortField={sortField}
                                    sortDirection={sortDirection}
                                    allNews={allNews}
                                    historicalStats={historicalStats}
                                    selectedDate={selectedDate}
                                    forceUpdate={statsUpdateCounter}
                                    onTotalsCalculated={setTableTotals}
                                />
                                <tbody>
                                    {news.map((item) => (
                                        <NewsTableRow
                                            key={item._id}
                                            item={item}
                                            selectedItems={selectedItems}
                                            handleSelectItem={handleSelectItem}
                                            handleToggleActive={handleToggleActive}
                                            handleDelete={handleDelete}
                                            showMenu={showMenu}
                                            setShowMenu={setShowMenu}
                                            getLanguageName={getLanguageName}
                                            selectedDate={selectedDate}
                                            historicalStats={historicalStats}
                                        />
                                    ))}
                                </tbody>
                            </table>
                            <NewsTablePagination 
                                news={news}
                                setNews={setNews}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalCount={totalCount}
                                setTotalCount={setTotalCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                searchQuery={searchQuery}
                                statusFilter={statusFilter}
                                languageFilter={languageFilter}
                                setIsLoading={setIsLoading}
                                setError={setError}
                                navigate={navigate}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NewsList;