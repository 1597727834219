import React, { useRef, useEffect } from 'react';
import { FILTER_CONSTANTS } from '../../../../utils/constants';
import { formatStatusFilter } from '../../../../utils/formatters';
import FilterButton from './FilterButton';

const StatusFilter = ({
    statusFilter,
    setStatusFilter,
    showStatusMenu,
    setShowStatusMenu,
    handleStatusChange
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowStatusMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowStatusMenu]);

    return (
        <div ref={menuRef}>
            <FilterButton
                label="Статус"
                value={FILTER_CONSTANTS.STATUS_LABELS[statusFilter]}
                onClick={() => setShowStatusMenu(!showStatusMenu)}
                isActive={statusFilter !== FILTER_CONSTANTS.STATUSES.ALL}
                showMenu={showStatusMenu}
            >
                <button
                    className="filter-option"
                    onClick={() => handleStatusChange(FILTER_CONSTANTS.STATUSES.ALL)}
                >
                    {FILTER_CONSTANTS.STATUS_LABELS.all}
                </button>
                <button
                    className="filter-option"
                    onClick={() => handleStatusChange(FILTER_CONSTANTS.STATUSES.ACTIVE)}
                >
                    {FILTER_CONSTANTS.STATUS_LABELS.active}
                </button>
                <button
                    className="filter-option"
                    onClick={() => handleStatusChange(FILTER_CONSTANTS.STATUSES.INACTIVE)}
                >
                    {FILTER_CONSTANTS.STATUS_LABELS.inactive}
                </button>
            </FilterButton>
        </div>
    );
};

export default StatusFilter; 