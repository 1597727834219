import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, subDays, startOfDay, endOfDay } from 'date-fns';
import axios from 'axios';
import AdminLayout from '../components/Layout';
import StatsCard from '../components/Dashboard/StatsCard';
import DateRangePicker from '../components/DateRangePicker/DateRangePicker';

const API_URL = process.env.REACT_APP_API_URL;

const Dashboard = () => {
    const today = new Date();
    const [dateRange, setDateRange] = useState({
        startDate: startOfDay(subDays(today, 6)), // 6 дней назад
        endDate: endOfDay(today) // сегодня до конца дня
    });

    // Изменяем начальное состояние для отображения двух графиков
    const [selectedChartTypes, setSelectedChartTypes] = useState(['clicks', 'adsClicks']);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [stats, setStats] = useState({
        period: {
            views: 0,
            clicks: 0,
            ctr: 0,
            adsViews: 0,
            adsClicks: 0,
            adsCtr: 0
        },
        chartData: []
    });

    // Получение данных с сервера
    useEffect(() => {
        const fetchStats = async () => {
            try {
                setIsLoading(true);
                const token = localStorage.getItem('token');
                
                if (!token) {
                    throw new Error('Необходима авторизация');
                }

                const response = await axios.get(`${API_URL}/admin/stats/dashboard`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                const { chartData, totalStats } = response.data.data;

                // Форматируем даты с учетом московского времени
                const formattedChartData = chartData.map(day => {
                    const date = new Date(day.date + 'T00:00:00');
                    return {
                        ...day,
                        date: format(date, 'dd.MM'),
                        originalDate: day.date
                    };
                });

                // Сортируем данные по дате
                formattedChartData.sort((a, b) => 
                    new Date(a.originalDate) - new Date(b.originalDate)
                );

                setStats({
                    period: totalStats,
                    chartData: formattedChartData
                });
                setError(null);
            } catch (error) {
                console.error('Error fetching stats:', error);
                setError(error.message || 'Ошибка получения данных');
            } finally {
                setIsLoading(false);
            }
        };

        fetchStats();
        
        // Обновление данных каждые 5 минут
        const timer = setInterval(fetchStats, 5 * 60 * 1000);
        return () => clearInterval(timer);
    }, []);

    // Конфигурация типов графиков
    const chartTypes = {
        views: {
            label: 'Показы (Трафик)',
            color: '#4F46E5',
            dataKey: 'views',
            formatter: (value) => value.toLocaleString()
        },
        clicks: {
            label: 'Клики (Трафик)',
            color: '#10B981',
            dataKey: 'clicks',
            formatter: (value) => value.toLocaleString()
        },
        ctr: {
            label: 'CTR (Трафик)',
            color: '#F59E0B',
            dataKey: 'ctr',
            formatter: (value) => `${value.toFixed(2)}%`
        },
        adsViews: {
            label: 'Показы (Реклама)',
            color: '#6366F1',
            dataKey: 'adsViews',
            formatter: (value) => value.toLocaleString()
        },
        adsClicks: {
            label: 'Клики (Реклама)',
            color: '#EC4899',
            dataKey: 'adsClicks',
            formatter: (value) => value.toLocaleString()
        },
        adsCtr: {
            label: 'CTR (Реклама)',
            color: '#8B5CF6',
            dataKey: 'adsCtr',
            formatter: (value) => `${value.toFixed(2)}%`
        }
    };

    // Вычисляем общую статистику
    const totalStats = {
        totalViews: stats.period.views + stats.period.adsViews,
        totalClicks: stats.period.clicks + stats.period.adsClicks,
        averageCTR: ((stats.period.clicks + stats.period.adsClicks) / (stats.period.views + stats.period.adsViews) * 100) || 0
    };

    const formatTooltip = (value, name, props) => {
        // Получаем конфигурацию для типа графика
        const chartConfig = chartTypes[name];
        if (!chartConfig) return value;
        
        // Форматируем значение с помощью форматтера из конфигурации
        return [chartConfig.formatter(value), chartConfig.label];
    };

    const handleChartTypeToggle = (type) => {
        setSelectedChartTypes(prev => {
            if (prev.includes(type)) {
                return prev.filter(t => t !== type);
            } else {
                return [...prev, type];
            }
        });
    };

    if (isLoading) {
        return (
            <AdminLayout>
                <div className="flex items-center justify-center h-96">
                    <div className="text-gray-500">Загрузка данных...</div>
                </div>
            </AdminLayout>
        );
    }

    if (error) {
        return (
            <AdminLayout>
                <div className="flex items-center justify-center h-96">
                    <div className="text-red-500">{error}</div>
                </div>
            </AdminLayout>
        );
    }

    return (
        <AdminLayout>
            <div className="space-y-6">
                {/* Заголовок с выбором периода */}
                <div className="flex justify-between items-center">
                    <h1 className="text-2xl font-semibold text-gray-900">Сводка</h1>
                    {/* Временно скрываем календарь */}
                    {/* <DateRangePicker
                        startDate={dateRange.startDate}
                        endDate={dateRange.endDate}
                        onRangeChange={handleDateRangeChange}
                    /> */}
                </div>

                {/* Карточки со статистикой */}
                <div className="bg-gray-50 p-6 rounded-xl">
                    <h2 className="text-lg font-medium text-gray-900 mb-4">Общая статистика за последние 7 дней</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <StatsCard
                            title="Показы"
                            value={totalStats.totalViews.toLocaleString()}
                            subValue={`Трафик: ${stats.period.views.toLocaleString()}, Реклама: ${stats.period.adsViews.toLocaleString()}`}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                            description="Общее количество показов"
                        />
                        <StatsCard
                            title="Клики"
                            value={totalStats.totalClicks.toLocaleString()}
                            subValue={`Трафик: ${stats.period.clicks.toLocaleString()}, Реклама: ${stats.period.adsClicks.toLocaleString()}`}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                            description="Общее количество кликов"
                        />
                        <StatsCard
                            title="CTR"
                            value={`${totalStats.averageCTR.toFixed(2)}%`}
                            subValue={`Трафик: ${stats.period.ctr.toFixed(2)}%, Реклама: ${stats.period.adsCtr.toFixed(2)}%`}
                            date={`${format(dateRange.startDate, 'dd.MM.yyyy')} — ${format(dateRange.endDate, 'dd.MM.yyyy')}`}
                            description="Средний CTR"
                        />
                    </div>
                </div>

                {/* График */}
                <div className="bg-white p-6 rounded-xl shadow-sm">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
                        <h2 className="text-lg font-medium text-gray-900">
                            Статистика за период
                        </h2>
                        <div className="flex flex-wrap gap-2">
                            {Object.entries(chartTypes).map(([type, config]) => (
                                <button
                                    key={type}
                                    onClick={() => handleChartTypeToggle(type)}
                                    className={`px-4 py-2 rounded-lg text-sm font-medium transition-colors ${
                                        selectedChartTypes.includes(type)
                                            ? 'bg-indigo-100 text-indigo-600'
                                            : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                                    }`}
                                >
                                    {config.label}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="h-[400px]">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart 
                                data={stats.chartData}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid 
                                    strokeDasharray="3 3" 
                                    stroke="#E5E7EB" 
                                    vertical={false}
                                />
                                <XAxis 
                                    dataKey="date" 
                                    stroke="#6B7280"
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    padding={{ left: 10, right: 10 }}
                                />
                                <YAxis 
                                    stroke="#6B7280"
                                    fontSize={12}
                                    tickLine={false}
                                    axisLine={false}
                                    tickFormatter={(value) => value.toLocaleString()}
                                />
                                <Tooltip 
                                    formatter={formatTooltip}
                                    contentStyle={{
                                        backgroundColor: 'white',
                                        border: '1px solid #E5E7EB',
                                        borderRadius: '6px',
                                        padding: '8px 12px'
                                    }}
                                    labelStyle={{
                                        color: '#6B7280',
                                        marginBottom: '4px'
                                    }}
                                    labelFormatter={(label) => `Дата: ${label}`}
                                />
                                {selectedChartTypes.map(type => (
                                    <Line 
                                        key={type}
                                        type="monotone" 
                                        dataKey={chartTypes[type].dataKey}
                                        name={type}
                                        stroke={chartTypes[type].color}
                                        strokeWidth={2}
                                        dot={{
                                            r: 4,
                                            fill: chartTypes[type].color,
                                            strokeWidth: 2
                                        }}
                                        activeDot={{
                                            r: 6,
                                            stroke: chartTypes[type].color,
                                            strokeWidth: 2,
                                            fill: 'white'
                                        }}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </div>
            </div>
        </AdminLayout>
    );
};

export default Dashboard;