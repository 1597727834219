import React, { useRef, useEffect } from 'react';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import LinkIcon from '@mui/icons-material/Link';
import DeleteIcon from '@mui/icons-material/Delete';

const BulkActionsButton = ({ 
    selectedItems, 
    showBulkActionsMenu, 
    setShowBulkActionsMenu, 
    onBulkAction 
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowBulkActionsMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowBulkActionsMenu]);

    return (
        <div className="bulk-actions-container" ref={menuRef}>
            <div style={{ position: 'relative' }}>
                <button
                    onClick={() => selectedItems.size > 0 && setShowBulkActionsMenu(!showBulkActionsMenu)}
                    className={`bulk-actions-button ${selectedItems.size > 0 ? 'has-selection' : ''}`}
                >
                    <span style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                        {selectedItems.size > 0 ? (
                            <>
                                Выбрано объявлений:
                                <span className="bulk-actions-counter">
                                    {selectedItems.size}
                                </span>
                            </>
                        ) : (
                            'Выберите объявления'
                        )}
                    </span>
                </button>
                {showBulkActionsMenu && selectedItems.size > 0 && (
                    <div className="bulk-actions-menu">
                        <button 
                            className="bulk-action-button"
                            onClick={() => {
                                onBulkAction('activate');
                                setShowBulkActionsMenu(false);
                            }}
                        >
                            <PlayArrowIcon style={{ fontSize: 18 }} />
                            Включить
                        </button>
                        <button 
                            className="bulk-action-button"
                            onClick={() => {
                                onBulkAction('deactivate');
                                setShowBulkActionsMenu(false);
                            }}
                        >
                            <PauseIcon style={{ fontSize: 18 }} />
                            Выключить
                        </button>
                        <button 
                            className="bulk-action-button"
                            onClick={() => {
                                onBulkAction('changeUrl');
                                setShowBulkActionsMenu(false);
                            }}
                        >
                            <LinkIcon style={{ fontSize: 18 }} />
                            Изменить URL
                        </button>
                        <button 
                            className="bulk-action-button"
                            style={{ color: '#dc2626' }}
                            onClick={() => {
                                onBulkAction('delete');
                                setShowBulkActionsMenu(false);
                            }}
                        >
                            <DeleteIcon style={{ fontSize: 18 }} />
                            Удалить
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default BulkActionsButton; 