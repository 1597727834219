import React, { useState, useEffect } from 'react';
import axios from 'axios';

// Используем переменную окружения напрямую
const API_URL = process.env.REACT_APP_API_URL;

// Расчет веса на основе CTR за 7 дней
const calculateWeight = (stats, averageCTR) => {
    if (!stats || !averageCTR || !stats.ctr || averageCTR.averageCTR === 0) {
        return 0.05; // Минимальный вес 5%
    }

    // Рассчитываем процентное отклонение от среднего
    const percentDiff = ((stats.ctr - averageCTR.averageCTR) / averageCTR.averageCTR) * 100;
    
    // Базовый вес 45% (0.45) - точка отсчета для среднего CTR
    let weight = 0.45;
    
    if (percentDiff > 0) {
        // Если CTR выше среднего
        // До 25% выше -> увеличиваем до 55%
        // До 50% выше -> увеличиваем до 65%
        // До 75% выше -> увеличиваем до 75%
        // Выше 75% -> увеличиваем до 99%
        if (percentDiff > 75) {
            weight = 0.99;
        } else if (percentDiff > 50) {
            weight = 0.75 + (percentDiff - 50) * 0.0096; // Плавное увеличение от 75% до 99%
        } else if (percentDiff > 25) {
            weight = 0.65 + (percentDiff - 25) * 0.004; // Плавное увеличение от 65% до 75%
        } else {
            weight = 0.55 + percentDiff * 0.004; // Плавное увеличение от 55% до 65%
        }
    } else {
        // Если CTR ниже среднего
        // До -15% -> уменьшаем до 35%
        // До -30% -> уменьшаем до 25%
        // Ниже -30% -> уменьшаем до 15%
        if (percentDiff < -30) {
            weight = Math.max(0.15, 0.25 + (percentDiff + 30) * 0.003);
        } else if (percentDiff < -15) {
            weight = Math.max(0.25, 0.35 + (percentDiff + 15) * 0.006);
        } else {
            weight = 0.45 + percentDiff * 0.006;
        }
    }
    
    const finalWeight = Math.min(Math.max(weight, 0.05), 0.99);
    
    return finalWeight;
};

const NewsWeight = ({ news }) => {
    const [weight, setWeight] = useState(0.05);
    const [stats, setStats] = useState(null);
    const [averageCTR, setAverageCTR] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isLearning, setIsLearning] = useState(false);
    const [lastUpdate, setLastUpdate] = useState(Date.now());

    useEffect(() => {
        // Проверка наличия токена
        const checkAuth = () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found');
                setError('Необходима авторизация');
                return false;
            }
            return token;
        };

        // Получение данных о весе новости с сервера
        const fetchNewsWeight = async () => {
            const token = checkAuth();
            if (!token) return;

            setIsLoading(true);
            
            try {
                const response = await axios.get(`${API_URL}/admin/news-weight/weight/${news._id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                const { weight, isLearning, totalStats } = response.data.data;

                setWeight(weight);
                setStats(totalStats);
                setIsLearning(isLearning);
                setError(null);

                // Получаем средний CTR для языка новости
                if (news.language && !isLearning) {
                    try {
                        const avgResponse = await axios.get(`${API_URL}/admin/news-weight/average-ctr/${news.language}`, {
                            headers: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                        
                        const avgData = avgResponse.data.data;
                        setAverageCTR(avgData);
                    } catch (err) {
                        console.error(`Ошибка при получении среднего CTR для языка ${news.language}:`, err);
                    }
                }
            } catch (error) {
                console.error('Error fetching news weight:', error);
                setError('Ошибка получения данных');
                setWeight(0.05);
            } finally {
                setIsLoading(false);
            }
        };

        fetchNewsWeight();
        
        // Обновление данных каждые 5 минут
        const timer = setInterval(() => {
            setLastUpdate(Date.now());
            fetchNewsWeight();
        }, 5 * 60 * 1000);

        return () => clearInterval(timer);
    }, [news._id, news.language]);

    // Определение цвета индикатора в зависимости от веса
    const getColorByWeight = (weight) => {
        if (weight >= 0.55) return '#4CAF50';      // зеленый - CTR выше среднего
        if (weight >= 0.35) return '#FFC107';      // желтый - CTR около среднего
        return '#EF4444';                          // красный - CTR ниже среднего
    };

    // Описание уровня охвата
    const getReachDescription = (weight) => {
        if (weight >= 0.55) return 'Высокий охват';
        if (weight >= 0.35) return 'Средний охват';
        return 'Низкий охват';
    };

    // Функция для получения текста сравнения CTR
    const getCTRComparisonText = () => {
        if (!stats || !averageCTR || averageCTR.averageCTR === 0) return null;
        
        const diff = stats.ctr - averageCTR.averageCTR;
        const percentDiff = averageCTR.averageCTR > 0 ? (diff / averageCTR.averageCTR) * 100 : 0;
        
        return { 
            className: 'ctr-comparison',
            percentDiff
        };
    };

    // Отображение состояния загрузки
    if (isLoading) {
        return (
            <td className="table-cell compact">
                <div className="loading-state">
                    <div className="loading-text">
                        Загрузка данных
                        <div className="loading-dots-wave">
                            <div className="dot"></div>
                            <div className="dot"></div>
                            <div className="dot"></div>
                        </div>
                    </div>
                </div>
            </td>
        );
    }

    // Отображение для новостей на обучении
    if (isLearning) {
        return (
            <td className="table-cell compact">
                <div className="news-weight-column">
                    <div className="learning-badge">
                        На обучении
                    </div>
                    <div className="weight-stats">
                        <div className="stats-period">За последние 7 дней:</div>
                        <div className="stats-main-row">
                            <div className="stats-item">
                                <span className="stats-value">{stats?.views || 0}</span>
                                <span className="stats-label">просмотров</span>
                            </div>
                            <div className="stats-item">
                                <span className="stats-value">{stats?.clicks || 0}</span>
                                <span className="stats-label">кликов</span>
                            </div>
                        </div>
                        <div className="stats-ctr">
                            CTR: {stats?.ctr?.toFixed(2) || 0}%
                        </div>
                    </div>
                </div>
            </td>
        );
    }

    // Отображение ошибки
    if (error) {
        return (
            <td className="table-cell compact">
                <div className="loading-state">
                    <div className="error-badge">
                        {error}
                    </div>
                </div>
            </td>
        );
    }

    const color = getColorByWeight(weight);
    const description = getReachDescription(weight);
    const ctrComparison = getCTRComparisonText();

    return (
        <td className="table-cell compact">
            <div className="news-weight-column">
                <div className="weight-bar-wrapper">
                    <div className="weight-bar-container">
                        <div 
                            className="weight-bar"
                            style={{
                                width: `${weight * 100}%`,
                                backgroundColor: color,
                                height: '100%',
                                borderRadius: '2px'
                            }}
                        />
                    </div>
                    <span className="weight-percentage">
                        {(weight * 100).toFixed(0)}%
                    </span>
                </div>
                <span 
                    className="weight-description"
                    style={{
                        backgroundColor: color
                    }}
                >
                    {description}
                </span>

                <div className="weight-stats">
                    <div className="stats-period">За последние 7 дней:</div>
                    <div className="stats-main-row">
                        <div className="stats-item">
                            <span className="stats-value">{stats?.views || 0}</span>
                            <span className="stats-label">просмотров</span>
                        </div>
                        <div className="stats-item">
                            <span className="stats-value">{stats?.clicks || 0}</span>
                            <span className="stats-label">кликов</span>
                        </div>
                    </div>
                    <div className="stats-ctr">
                        CTR: {stats?.ctr?.toFixed(2) || 0}%
                    </div>
                    
                    {ctrComparison && (
                        <div className="ctr-comparison-block">
                            <span style={{ 
                                width: '4px', 
                                height: '4px', 
                                borderRadius: '50%', 
                                backgroundColor: ctrComparison.percentDiff > 0 ? '#10B981' : '#EF4444'
                            }}></span>
                            <span style={{
                                color: ctrComparison.percentDiff > 0 ? '#10B981' : '#EF4444'
                            }}>
                                CTR на {Math.abs(ctrComparison.percentDiff).toFixed(0)}% {ctrComparison.percentDiff > 0 ? 'выше' : 'ниже'} среднего
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </td>
    );
};

export default NewsWeight; 