import React from 'react';

const FilterButton = ({ 
    label, 
    value, 
    onClick, 
    isActive, 
    showMenu, 
    children 
}) => (
    <div className="status-filter">
        <div>
            <button
                onClick={onClick}
                className="filter-button"
                style={{ backgroundColor: isActive ? '#e2e8f0' : 'white' }}
            >
                {label}: {value}
                <span style={{ marginLeft: '4px' }}>▼</span>
            </button>
            {showMenu && (
                <div className="status-menu">
                    {children}
                </div>
            )}
        </div>
    </div>
);

export default FilterButton; 