// Утилиты для расчета статистики просмотров, кликов и CTR в админ-панели

import { isSameDay } from 'date-fns';
import { FILTER_CONSTANTS } from './constants';

// Базовые расчеты
export const calculateCTR = (views, clicks) => {
    return views > 0 ? (clicks / views) * 100 : 0;
};

// Статистика по языкам
export const getStatsByLanguage = (news) => {
    const initialStats = {
        [FILTER_CONSTANTS.LANGUAGES.RU]: { active: 0, inactive: 0 },
        [FILTER_CONSTANTS.LANGUAGES.PL]: { active: 0, inactive: 0 },
        [FILTER_CONSTANTS.LANGUAGES.RO]: { active: 0, inactive: 0 }
    };

    return news.reduce((stats, item) => {
        if (stats[item.language]) {
            const statusKey = item.isActive ? 'active' : 'inactive';
            stats[item.language][statusKey]++;
        }
        return stats;
    }, initialStats);
};

// Общие суммы для таблицы
export const calculateTableTotals = (news, historicalStats = null, selectedDate = null) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return news.reduce((totals, item) => {
        let itemStats;

        if (selectedDate) {
            // Если выбрана дата, используем историческую статистику
            itemStats = calculateStats(item, historicalStats, selectedDate);
            return {
                views: totals.views + itemStats.traffic.views,
                clicks: totals.clicks + itemStats.traffic.clicks,
                adsViews: totals.adsViews + itemStats.ads.views,
                adsClicks: totals.adsClicks + itemStats.ads.clicks
            };
        } else {
            // Если дата не выбрана, используем текущую статистику
            return {
                views: totals.views + (item.stats?.views || 0),
                clicks: totals.clicks + (item.stats?.clicks || 0),
                adsViews: totals.adsViews + (item.adsStats?.views || 0),
                adsClicks: totals.adsClicks + (item.adsStats?.clicks || 0)
            };
        }
    }, {
        views: 0,
        clicks: 0,
        adsViews: 0,
        adsClicks: 0
    });
};

// Форматирование статистики
const formatStats = (rawStats) => {
    if (!rawStats) {
        return {
            traffic: {
                views: 0,
                clicks: 0,
                ctr: 0
            },
            ads: {
                views: 0,
                clicks: 0,
                ctr: 0
            }
        };
    }

    return {
        traffic: {
            views: rawStats?.traffic?.views || 0,
            clicks: rawStats?.traffic?.clicks || 0,
            ctr: calculateCTR(rawStats?.traffic?.views || 0, rawStats?.traffic?.clicks || 0)
        },
        ads: {
            views: rawStats?.ads?.views || 0,
            clicks: rawStats?.ads?.clicks || 0,
            ctr: calculateCTR(rawStats?.ads?.views || 0, rawStats?.ads?.clicks || 0)
        }
    };
};

// Получение текущей статистики
const getCurrentStats = (item) => {
   return formatStats({
       traffic: {
           views: item.stats?.views || 0,
           clicks: item.stats?.clicks || 0
       },
       ads: {
           views: item.adsStats?.views || 0,
           clicks: item.adsStats?.clicks || 0
       }
   });
};

// Расчет исторической статистики
const calculateHistoricalStats = (historicalStats, item, startDate, endDate) => {
   if (!historicalStats?.length) return formatStats(null);

   return historicalStats
       .filter(stat => {
           if (!stat?.advertisementId) return false;
           
           const itemIdString = item._id.toString();
           const statIdString = stat.advertisementId.toString();
           const isMatch = itemIdString === statIdString;
           const statDate = new Date(stat.date);
           
           return isMatch && 
                  statDate >= startDate && 
                  statDate <= endDate;
       })
       .reduce((acc, stat) => {
           const statData = formatStats(stat.stats);
           return {
               traffic: {
                   views: acc.traffic.views + statData.traffic.views,
                   clicks: acc.traffic.clicks + statData.traffic.clicks,
                   ctr: calculateCTR(
                       acc.traffic.views + statData.traffic.views,
                       acc.traffic.clicks + statData.traffic.clicks
                   )
               },
               ads: {
                   views: acc.ads.views + statData.ads.views,
                   clicks: acc.ads.clicks + statData.ads.clicks,
                   ctr: calculateCTR(
                       acc.ads.views + statData.ads.views,
                       acc.ads.clicks + statData.ads.clicks
                   )
               }
           };
       }, formatStats(null));
};

// Комбинирование текущей и исторической статистики
const combineStats = (historical, current) => {
   return {
       traffic: {
           views: historical.traffic.views + current.traffic.views,
           clicks: historical.traffic.clicks + current.traffic.clicks,
           ctr: calculateCTR(
               historical.traffic.views + current.traffic.views,
               historical.traffic.clicks + current.traffic.clicks
           )
       },
       ads: {
           views: historical.ads.views + current.ads.views,
           clicks: historical.ads.clicks + current.ads.clicks,
           ctr: calculateCTR(
               historical.ads.views + current.ads.views,
               historical.ads.clicks + current.ads.clicks
           )
       }
   };
};

// Основная функция расчета статистики
export const calculateStats = (item, historicalStats, selectedDate) => {
   const today = new Date();
   today.setHours(0, 0, 0, 0);

   // Если выбран текущий день
   if (selectedDate?.endDate && isSameDay(selectedDate.endDate, today)) {
       const historical = calculateHistoricalStats(historicalStats, item, selectedDate.startDate, today);
       const current = getCurrentStats(item);
       return combineStats(historical, current);
   }

   // Если выбран период
   if (selectedDate) {
       return calculateHistoricalStats(historicalStats, item, selectedDate.startDate, selectedDate.endDate);
   }

   // Если нет выбранной даты
   return getCurrentStats(item);
};