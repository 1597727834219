import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import '../../styles/style.css';
import '../../styles/states.css';
import RelatedNewsBlock from '../components/RelatedNewsBlock';
import { newsAPI } from '../utils/api';
import { registerView, handleNewsClick, registerAdView, handleAdClick } from '../utils/newsInteractions';

const NewsDetail = React.memo(() => {
  const [news, setNews] = useState(null);
  const [error, setError] = useState(null);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [newsLanguage, setNewsLanguage] = useState('ru');
  const { slug } = useParams();
  const viewRegistered = useRef(false);
  const isComponentMounted = useRef(true);
  const abortController = useRef(null);
  const imageRef = useRef(null);
  const [newsId, setNewsId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobileView, setIsMobileView] = useState(false);
  
  // Новые состояния для централизованного управления новостями
  const [allNews, setAllNews] = useState([]);
  const [topNews, setTopNews] = useState(null);
  const [relatedNews, setRelatedNews] = useState([]);

  const detectLanguage = (text) => {
    const polishChars = /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/;
    const romanianChars = /[ăâîșțĂÂÎȘȚ]/;
    
    if (polishChars.test(text)) {
      return 'pl';
    }
    if (romanianChars.test(text)) {
      return 'ro';
    }
    return 'ru';
  };

  const getButtonText = (lang) => {
    switch (lang) {
      case 'pl':
        return 'Czytaj więcej';
      case 'ro':
        return 'Citește mai mult';
      default:
        return 'Читать полностью';
    }
  };

  // Проверка мобильного вида
  useEffect(() => {
    const checkMobileView = () => {
      const mediaQuery = window.matchMedia('(max-width: 580px)');
      setIsMobileView(mediaQuery.matches);
    };

    checkMobileView();
    window.addEventListener('resize', checkMobileView);

    return () => {
      window.removeEventListener('resize', checkMobileView);
    };
  }, []);

  const fetchNewsCallback = React.useCallback(async () => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`/api/public/news/by-slug/${slug}`, {
          headers: {
            'Cache-Control': 'max-age=3600'
          }
        });
        
        if (!isComponentMounted.current) return;
        
        if (!response.data) {
          setError('Новость не найдена');
          return;
        }

        const newsData = {
          _id: response.data._id,
          title: response.data.title,
          description: response.data.description || response.data.title,
          image_url: response.data.imageUrl.startsWith('http')
            ? response.data.imageUrl
            : `${window.location.origin}${response.data.imageUrl}`,
          sourceUrl: response.data.sourceUrl,
          publishedAt: response.data.publishedAt,
          category: response.data.category
        };
        
        setNews(newsData);
        setNewsId(response.data._id);
        const detectedLanguage = detectLanguage(newsData.title);
        setNewsLanguage(detectedLanguage);
        
        // После загрузки основной новости, загружаем связанные
        fetchRelatedNews(response.data._id, detectedLanguage);
      } catch (error) {
        if (isComponentMounted.current) {
          setError(error.response?.data?.message || 'Новость не найдена');
          setIsLoading(false);
        }
      }
    };

    isComponentMounted.current = true;
    viewRegistered.current = false;
    setIsImageLoaded(false);
    setIsLoading(true);
    fetchNews();

    return () => {
      isComponentMounted.current = false;
    };
  }, [slug]);

  // Функция загрузки связанных новостей - теперь управляет всем распределением
  const fetchRelatedNews = async (newsId, language) => {
    try {
      const response = await newsAPI.getNews(language);
      
      if (!Array.isArray(response.data) || !isComponentMounted.current) {
        setIsLoading(false);
        return;
      }
      
      // Фильтруем новости, исключая текущую новость
      const filteredNews = response.data
        .filter(news => 
          news._id !== newsId && 
          news.isActive && 
          news.language === language
        )
        .map(news => ({
          ...news,
          image_url: news.imageUrl.startsWith('http')
            ? news.imageUrl
            : `${window.location.origin}${news.imageUrl}`
        }));
      
      // Перемешиваем новости для случайного порядка
      const shuffledNews = [...filteredNews].sort(() => Math.random() - 0.5);
      
      // Принудительно проверяем медиа-запрос заново
      const isMobile = window.matchMedia('(max-width: 580px)').matches;
      
      if (shuffledNews.length > 0) {
        if (isMobile) {
          // В мобильном виде - первую новость показываем сверху, остальные снизу
          const firstNews = shuffledNews[0];
          const restNews = shuffledNews.slice(1);
          
          setTopNews(firstNews);
          setRelatedNews(restNews);
          setIsMobileView(true);
        } else {
          // В десктопном виде - показываем все новости в блоке релейтед
          setTopNews(null);
          setRelatedNews(shuffledNews);
          setIsMobileView(false);
        }
        
        // Сохраняем полный список для использования при повторах
        setAllNews(shuffledNews);
      }
      
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNewsCallback();
  }, [fetchNewsCallback]);

  const handleImageLoad = React.useCallback(() => {
    if (isComponentMounted.current) {
      setIsImageLoaded(true);
    }
  }, []);

  const handleClick = React.useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (!news?._id || !slug) return;
    await handleNewsClick(news._id, slug, news.sourceUrl);
  }, [news, slug]);

  // Обработчик клика для верхнего блока
  const handleTopBlockClick = React.useCallback(async (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    if (!topNews?._id || !topNews?.slug) return;
    
    try {
      await handleAdClick(topNews._id, topNews.slug);
    } catch (error) {
      // Ошибка при обработке клика
    }
  }, [topNews]);

  useEffect(() => {
    if (news && isImageLoaded && !viewRegistered.current) {
      registerView(news._id);
      viewRegistered.current = true;
    }
  }, [news, isImageLoaded]);

  // Регистрация просмотра для верхнего блока
  useEffect(() => {
    if (topNews && isMobileView) {
      registerAdView(topNews._id);
    }
  }, [topNews, isMobileView]);

  if (error) {
    return (
      <>
        <Helmet>
          <title>Ошибка | Новость не найдена</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="container container-list w-100">
          <div className="error-container">
            <div className="error-box">
              <div className="error-icon"></div>
              <h2 className="error-title">Ошибка</h2>
              <p className="error-message">{error}</p>
              <p className="error-message">Возможно, новость была удалена или перемещена.</p>
              <button 
                className="error-button"
                onClick={() => window.location.href = '/'}
              >
                Вернуться на главную
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  if (!news || isLoading) {
    return (
      <>
        <Helmet>
          <title>Loading...</title>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="container container-list w-100">
          <div className="loading-container">
            <div className="loading-spinner" />
          </div>
        </div>
      </>
    );
  }

  const redirectUrl = `/forward/news/${slug}`;
  const canonicalUrl = `${window.location.origin}/news/${slug}`;

  return (
    <>
      <Helmet>
        <title>{news.title}</title>
        <link rel="preload" href={news.image_url} as="image" />
        <meta name="description" content={news.title} />
        <meta property="og:title" content={news.title} />
        <meta property="og:description" content={news.title} />
        <meta property="og:image" content={news.image_url} />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={news.title} />
        <meta name="twitter:description" content={news.title} />
        <meta name="twitter:image" content={news.image_url} />
        <link rel="canonical" href={canonicalUrl} />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "headline": news.title,
            "image": [news.image_url],
            "datePublished": news.publishedAt,
            "url": canonicalUrl
          })}
        </script>
      </Helmet>
      <div className="container container-list w-100">
        <div className="items-box" style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* Мобильный верхний блок - показываем только если есть topNews и на мобильном */}
          {isMobileView && topNews && (
            <div className="mobile-top-news" style={{ width: '100%' }}>
              <div className="item item-t item-sm news-item top-news-item" data-news-id={topNews._id}>
                <div className="teaser">
                  <a 
                    href={`/out/news/${topNews.slug}`}
                    onClick={handleTopBlockClick}
                    className="dynamic-load"
                    rel="noopener noreferrer"
                  >
                    <div className="item-overlay"></div>
                    <div className="item-image">
                      <img 
                        src={topNews.image_url} 
                        alt={topNews.title}
                        loading="lazy"
                      />
                    </div>
                    <div className="item-title" data-slug={topNews.slug}>
                      {topNews.title}
                    </div>
                  </a>
                </div>
              </div>
            </div>
          )}
          
          <div className="item item-t item-p">
            <div className="article-preview">
              <a
                href={redirectUrl}
                onClick={handleClick}
                className="article-link"
                rel="noopener noreferrer"
                style={{ cursor: 'pointer' }}
              />
              <div className="item-overlay" />
              <div className="item-overlay-blur">
                <img 
                  src={news.image_url} 
                  alt={news.title}
                  onLoad={handleImageLoad}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/fallback-image.jpg';
                  }}
                  ref={imageRef}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="item-image">
                <img 
                  src={news.image_url} 
                  alt={news.title}
                  onLoad={handleImageLoad}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/fallback-image.jpg';
                  }}
                  ref={imageRef}
                  loading="lazy"
                  width="100%"
                  height="100%"
                />
              </div>
              <div className="item-title" data-slug={slug}>{news.title}</div>
              <div className="item-button">{getButtonText(newsLanguage)}</div>
            </div>
          </div>
          
          {/* Блок связанных новостей - передаем уже готовый массив новостей */}
          {relatedNews.length > 0 && (
            <div className="items-box" id="related-news" style={{ display: 'contents' }}>
              <RelatedNewsBlock 
                currentNewsId={newsId}
                currentLanguage={newsLanguage}
                preparedNews={relatedNews}
                allAvailableNews={allNews}
                topNewsId={topNews?._id} 
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
});

export default NewsDetail;