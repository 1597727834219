export const sortNews = (news, field, direction) => {
    if (!field) return news;

    return [...news].sort((a, b) => {
        let aValue, bValue;
        
        switch(field) {
            case 'views':
                aValue = a.stats?.views || 0;
                bValue = b.stats?.views || 0;
                break;
            case 'clicks':
                aValue = a.stats?.clicks || 0;
                bValue = b.stats?.clicks || 0;
                break;
            case 'ctr':
                aValue = a.stats?.views ? (a.stats.clicks / a.stats.views) * 100 : 0;
                bValue = b.stats?.views ? (b.stats.clicks / b.stats.views) * 100 : 0;
                break;
            case 'adsViews':
                aValue = a.adsStats?.views || 0;
                bValue = b.adsStats?.views || 0;
                break;
            case 'adsClicks':
                aValue = a.adsStats?.clicks || 0;
                bValue = b.adsStats?.clicks || 0;
                break;
            case 'adsCtr':
                aValue = a.adsStats?.views ? (a.adsStats.clicks / a.adsStats.views) * 100 : 0;
                bValue = b.adsStats?.views ? (b.adsStats.clicks / b.adsStats.views) * 100 : 0;
                break;
            default:
                return 0;
        }
        
        return direction === 'asc' ? aValue - bValue : bValue - aValue;
    });
}; 