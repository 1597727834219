import axios from 'axios';

// Базовый URL API
const API_URL = process.env.REACT_APP_API_URL;
if (!API_URL) {
    throw new Error('REACT_APP_API_URL не установлен в переменных окружения');
}

// Создаем instance axios с базовыми настройками
export const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Перехватчик запросов только для критических ошибок
api.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

// Перехватчик ответов только для критических ошибок
api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

// Методы для работы с новостями
export const newsAPI = {
  // Получение всех активных новостей
  getNews: (language) => api.get('/public/news/active', {
    params: { language }
  }),

  // Получение новости по slug
  getBySlug: (slug) => api.get(`/public/news/by-slug/${slug}`),
  
  // Получение новости по ID
  getById: (id) => api.get(`/public/news/by-id/${id}`),
  
  // Основные новости
  registerView: (newsId) => api.post(`/public/news/view/${newsId}`),
  registerClick: (newsId) => api.post(`/public/news/click/${newsId}`),
  getRedirectUrl: (slug) => api.get(`/forward/news/${slug}`),
  
  // Рекламные новости
  registerAdView: (newsId) => api.post(`/public/news/ad-view/${newsId}`),
  registerAdClick: (newsId) => api.post(`/public/news/ad-click/${newsId}`),
  getAdRedirectUrl: (slug) => api.get(`/out/news/${slug}`),

  // Получение языка новости по slug
  getLanguageBySlug: (slug) => api.get(`/public/news/language/${slug}`),

  // Добавляем новый метод для получения отфильтрованных новостей
  getFilteredNews: async (filters) => {
    try {
      const token = localStorage.getItem('token');
      const response = await api.get('/admin/news', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: {
          page: filters.page || 1,
          limit: filters.limit || 1000,
          search: filters.search,
          status: filters.status === 'active' ? 'active' : 
                 filters.status === 'inactive' ? 'inactive' : undefined,
          language: filters.language !== 'all' ? 
            filters.language : undefined
        }
      });
      return response.data;
    } catch (error) {
      console.error('Ошибка при получении отфильтрованных новостей:', error);
      throw error;
    }
  },

  getHistoricalStats: async (newsIds, startDate, endDate) => {
    const token = localStorage.getItem('token');
    return api.get('/admin/news/historical-stats', {
      headers: {
        Authorization: `Bearer ${token}`
      },
      params: {
        ids: newsIds.join(','),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString()
      }
    });
  }
};

// Функция для получения статистики
export const fetchNewsStats = async (newsIds) => {
    try {
        const response = await api.get('/admin/news/stats', {
            params: { ids: newsIds.join(',') }
        });
        return response.data;
    } catch (error) {
        console.error('Ошибка при запросе статистики:', error);
        return {};
    }
};

// Добавляем методы для работы с настройками
export const settingsAPI = {
  getPublicSettings: () => api.get('/public/settings'),
};

// Экспортируем api как default
export default api;