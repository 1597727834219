// Компонент для отображения заголовка новости и URL источника в таблице админ-панели

import React from 'react';

const NewsTitle = ({ title, sourceUrl }) => {
    return (
        <div className="news-title-container">
            <div className="news-title">
                {title}
            </div>
            <div className="url-container">
                <a 
                    href={sourceUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="url"
                >
                    {sourceUrl}
                </a>
            </div>
        </div>
    );
};

export default NewsTitle;