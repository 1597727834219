// Компонент заголовков таблицы с общей статистикой в админ-панели

import React, { useEffect, useMemo } from 'react';
import SortArrows from './SortArrows';
import '../../../../../styles/NewsTableRow.css'; // Импортируем стили
import { calculateTableTotals } from '../../../../utils/statsCalculations';

const NewsTableHeaderColumns = ({ 
    handleSelectAll, 
    filteredAndSortedNews, 
    selectedItems, 
    handleSort, 
    sortField, 
    sortDirection,
    allNews,
    historicalStats,
    selectedDate,
    forceUpdate,
    onTotalsCalculated
}) => {
    const totals = useMemo(() => {
        if (!filteredAndSortedNews?.length) {
            return { views: 0, clicks: 0, adsViews: 0, adsClicks: 0 };
        }

        // Используем функцию calculateTableTotals для расчета итогов
        return calculateTableTotals(filteredAndSortedNews, historicalStats, selectedDate);
    }, [filteredAndSortedNews, historicalStats, selectedDate]);

    // Используем useEffect для вызова onTotalsCalculated
    useEffect(() => {
        if (onTotalsCalculated) {
            onTotalsCalculated(totals);
        }
    }, [totals, onTotalsCalculated]);

    const avgCtr = totals.views > 0 ? ((totals.clicks / totals.views) * 100).toFixed(2) : '0.00';
    const avgAdsCtr = totals.adsViews > 0 ? ((totals.adsClicks / totals.adsViews) * 100).toFixed(2) : '0.00';

    return (
        <>
            <thead>
                <tr>
                    <th className="table-header checkbox" rowSpan="2">
                        <input
                            type="checkbox"
                            onChange={handleSelectAll}
                            checked={selectedItems.size === filteredAndSortedNews.length}
                            className="checkbox"
                        />
                    </th>
                    <th className="table-header wide" rowSpan="2">Управление</th>
                    <th className="table-header full" rowSpan="2">Объявление</th>
                    <th className="table-header compact" rowSpan="2">Охват показов</th>
                    <th className="table-header stats" colSpan="3">ТРАФИК</th>
                    <th className="table-header stats" colSpan="3">РЕКЛАМА</th>
                </tr>
                <tr>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('views')}
                    >
                        <div className="table-header-content">
                            Показы
                            <SortArrows 
                                sortField={sortField} 
                                currentField="views" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('clicks')}
                    >
                        <div className="table-header-content">
                            Клики
                            <SortArrows 
                                sortField={sortField} 
                                currentField="clicks" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('ctr')}
                    >
                        <div className="table-header-content">
                            <span>CTR, %</span>
                            <SortArrows 
                                sortField={sortField} 
                                currentField="ctr" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('adsViews')}
                    >
                        <div className="table-header-content">
                            Показы
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsViews" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('adsClicks')}
                    >
                        <div className="table-header-content">
                            Клики
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsClicks" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                    <th 
                        className="table-header sortable" 
                        onClick={() => handleSort('adsCtr')}
                    >
                        <div className="table-header-content">
                            <span>CTR, %</span>
                            <SortArrows 
                                sortField={sortField} 
                                currentField="adsCtr" 
                                sortDirection={sortDirection}
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tfoot>
                <tr>
                    <td colSpan="3" className="footer-cell right">
                        Итого:
                    </td>
                    <td className="footer-cell">
                        {/* Пустая ячейка для колонки "Охват" */}
                    </td>
                    <td className="footer-cell">
                        {totals.views}
                    </td>
                    <td className="footer-cell">
                        {totals.clicks}
                    </td>
                    <td className="footer-cell">
                        {avgCtr}%
                    </td>
                    <td className="footer-cell">
                        {totals.adsViews}
                    </td>
                    <td className="footer-cell">
                        {totals.adsClicks}
                    </td>
                    <td className="footer-cell">
                        {avgAdsCtr}%
                    </td>
                </tr>
            </tfoot>
        </>
    );
};

export default NewsTableHeaderColumns;