import React from 'react';
import '../../../styles/NewsForm.css';

const InputWrapper = ({ label, error, children }) => (
    <div className="form-group">
        <label className={`label ${error ? 'label-error' : ''}`}>
            {label}
        </label>
        {children}
        {error && (
            <div className="error-message">
                {error}
            </div>
        )}
    </div>
);

export default InputWrapper;