import { getStatusForApi, matchesStatusFilter } from './statusHelpers';

// Функция для получения параметров фильтрации для API запроса
export const getCurrentFilters = (searchQuery, statusFilter, languageFilter, page = 1, limit = 1000) => {
    const filters = {
        page,
        limit,
        ...getFilterParams(searchQuery, statusFilter, languageFilter)
    };

    return filters;
};

// Функция для получения параметров фильтрации
export const getFilterParams = (searchQuery, statusFilter, languageFilter) => {
    const params = {
        ...(searchQuery && { search: searchQuery }),
        ...(statusFilter !== 'all' && { 
            isActive: getStatusForApi(statusFilter)
        }),
        ...(languageFilter !== 'all' && { language: languageFilter })
    };

    return params;
};

// Функция для фильтрации новостей на клиентской стороне
export const filterNews = (news, { searchQuery, statusFilter, languageFilter }) => {
    return news.filter(item => {
        const matchesSearch = !searchQuery ? true : (
            item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.sourceUrl.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item._id.toString().includes(searchQuery) ||
            item.orderNumber?.toString().includes(searchQuery)
        );

        // Используем функцию из statusHelpers
        const matchesStatus = matchesStatusFilter(item, statusFilter);

        const matchesLanguage = languageFilter === 'all' ? true : 
            item.language === languageFilter;

        return matchesSearch && matchesStatus && matchesLanguage;
    });
}; 