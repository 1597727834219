// Компонент для отображения статистики (просмотры, клики, CTR) в таблице админ-панели

import React from 'react';
import '../../../../../styles/NewsTableRow.css'; // Импортируем стили

const StatsDisplay = ({ stats }) => {
    // Проверяем наличие статистики, если нет - показываем нули
    const currentStats = stats || {
        traffic: { views: 0, clicks: 0, ctr: 0 },
        ads: { views: 0, clicks: 0, ctr: 0 }
    };

    return (
        <>
            {/* Статистика трафика */}
            <td className="stats-cell">
                {currentStats.traffic.views}
            </td>
            <td className="stats-cell">
                {currentStats.traffic.clicks}
            </td>
            <td className="stats-cell">
                {currentStats.traffic.ctr.toFixed(2)}%
            </td>

            {/* Рекламная статистика */}
            <td className="stats-cell">
                {currentStats.ads.views}
            </td>
            <td className="stats-cell">
                {currentStats.ads.clicks}
            </td>
            <td className="stats-cell">
                {currentStats.ads.ctr.toFixed(2)}%
            </td>
        </>
    );
};

export default StatsDisplay;