import React, { useRef, useEffect } from 'react';
import { FILTER_CONSTANTS } from '../../../../utils/constants';
import { formatLanguageFilter } from '../../../../utils/formatters';
import FilterButton from './FilterButton';

const LanguageFilter = ({
    languageFilter,
    setLanguageFilter,
    showLanguageMenu,
    setShowLanguageMenu,
    handleLanguageChange
}) => {
    const menuRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowLanguageMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setShowLanguageMenu]);

    return (
        <div ref={menuRef}>
            <FilterButton
                label="Язык"
                value={formatLanguageFilter(languageFilter)}
                onClick={() => setShowLanguageMenu(!showLanguageMenu)}
                isActive={languageFilter !== FILTER_CONSTANTS.LANGUAGES.ALL}
                showMenu={showLanguageMenu}
            >
                <button
                    className="filter-option"
                    onClick={() => handleLanguageChange(FILTER_CONSTANTS.LANGUAGES.ALL)}
                >
                    Все
                </button>
                <button
                    className="filter-option"
                    onClick={() => handleLanguageChange(FILTER_CONSTANTS.LANGUAGES.RU)}
                >
                    Русский
                </button>
                <button
                    className="filter-option"
                    onClick={() => handleLanguageChange(FILTER_CONSTANTS.LANGUAGES.PL)}
                >
                    Польский
                </button>
                <button
                    className="filter-option"
                    onClick={() => handleLanguageChange(FILTER_CONSTANTS.LANGUAGES.RO)}
                >
                    Румынский
                </button>
            </FilterButton>
        </div>
    );
};

export default LanguageFilter; 