// src/admin/pages/News/NewsForm.js

import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ImageCropModal from '../../components/ImageCrop/ImageCropModal';
import AdminLayout from '../../components/Layout';
import InputWrapper from '../../components/Forms/InputWrapper';
import ImageUpload from '../../components/Forms/ImageUpload';
import NewsPreview from '../../components/Preview/NewsPreview';
import { api, BASE_DOMAIN } from '../../utils/api';
import '../../../styles/NewsForm.css';

// Стили для уведомлений и предупреждений
const warningStyles = `
  .warning-box {
    transition: all 0.3s ease;
  }
  .warning-box:hover {
    box-shadow: 0 4px 12px rgba(180, 120, 0, 0.1);
  }
`;

const PLACEHOLDER_TEXT = 'Добавьте текст, чтобы увидеть, как будет выглядеть блок на сайте';
const PLACEHOLDER_IMAGE = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAwIiBoZWlnaHQ9IjMwMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cmVjdCB3aWR0aD0iNTAwIiBoZWlnaHQ9IjMwMCIgZmlsbD0iI2YzZjRmNiIvPjxwYXRoIGQ9Ik0xODUgMTQwaDEzMHYyMEgxODV6IiBmaWxsPSIjZDFkNWRiIi8+PHBhdGggZD0iTTIwMCAxNzBoMTAwdjEwSDIwMHoiIGZpbGw9IiNkMWQ1ZGIiLz48Y2lyY2xlIGN4PSIyNTAiIGN5PSI5MCIgcj0iMzAiIGZpbGw9IiNkMWQ1ZGIiLz48L3N2Zz4=';
const MAX_TITLE_LENGTH = 100;

const getCounterColor = (length, maxLength) => {
    const percentage = (length / maxLength) * 100;
    if (percentage < 50) return '#28a745';  // зеленый
    if (percentage < 75) return '#ffc107';  // желтый
    if (percentage < 90) return '#fd7e14';  // оранжевый
    return '#dc3545';  // красный
};

// Вспомогательная функция для безопасного добавления параметров к URL
const safelyAddParamToUrl = (url, paramName, paramValue) => {
    try {
        // Пытаемся разобрать URL
        const urlObj = new URL(url.startsWith('http') ? url : `https://${url}`);
        
        // Добавляем параметр
        urlObj.searchParams.set(paramName, paramValue);
        
        // Возвращаем URL в правильном формате
        if (!url.startsWith('http')) {
            return urlObj.toString().replace(/^https?:\/\//, '');
        }
        return urlObj.toString();
    } catch (e) {
        console.error('Ошибка при добавлении параметра к URL:', e);
        
        // Если URL некорректен, добавляем параметр вручную
        if (!url.includes('?')) {
            return `${url}?${paramName}=${encodeURIComponent(paramValue)}`;
        } else if (url.endsWith('?')) {
            return `${url}${paramName}=${encodeURIComponent(paramValue)}`;
        } else {
            return `${url}&${paramName}=${encodeURIComponent(paramValue)}`;
        }
    }
};

const NewsForm = ({ isEditing }) => {
    // State
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        title: '',
        sourceUrl: '',
        language: 'ru',
        utmSchema: 'disabled'
    });
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [currentImage, setCurrentImage] = useState(null);
    const [showCropModal, setShowCropModal] = useState(false);
    const [tempImage, setTempImage] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({
        title: '',
        sourceUrl: '',
        image: ''
    });
    const [isPaused, setIsPaused] = useState(false);
    const [activeAspectRatio, setActiveAspectRatio] = useState('1:1');
    const [showUtmOptions, setShowUtmOptions] = useState(false);
    const [orderNumber, setOrderNumber] = useState(null);
    const [customUtmParams, setCustomUtmParams] = useState({});

    // Effects
    useEffect(() => {
        if (isEditing && id) {
            fetchNews();
        }
    }, [isEditing, id]);

    // Функция для получения URL с UTM-метками
    const getUrlWithUtm = useCallback(() => {
        if (formData.utmSchema === 'disabled' || !formData.sourceUrl) {
            return formData.sourceUrl;
        }

        try {
            const hostname = window.location.hostname;
            const tid = isEditing ? (orderNumber || 'ID не найден') : '[будет создан после сохранения]';
            
            // Мы не заменяем макросы, а просто показываем URL для предпросмотра
            if (formData.utmSchema === 'custom') {
                // Для индивидуальных UTM-меток просто возвращаем исходный URL
                return formData.sourceUrl;
            }
            
            // Для стандартных UTM-меток добавляем параметры к URL
            const url = new URL(formData.sourceUrl);
            url.searchParams.set('utm_source', hostname);
            url.searchParams.set('utm_content', tid);
            return url.toString();
        } catch (e) {
            console.error('Ошибка при формировании URL с UTM-метками:', e);
            return formData.sourceUrl;
        }
    }, [formData.sourceUrl, formData.utmSchema, orderNumber, isEditing]);

    // Обработчик изменения схемы UTM-меток
    const handleUtmSchemaChange = (schema) => {
        setFormData(prev => ({
            ...prev,
            utmSchema: schema
        }));
        setShowUtmOptions(false);
    };

    // API Calls
    const fetchNews = async () => {
        try {
            const response = await api.get(`/admin/news/${id}`);
            const news = response.data;
            
            // Получаем значение utmSchema из данных сервера, если оно есть
            let utmSchema = news.utmSchema || 'disabled';
            
            // Если значение не пришло с сервера, пытаемся определить его на основе URL
            if (!news.utmSchema) {
                console.log('UTM Schema not found in data, trying to detect from URL');
                try {
                    // Проверяем наличие макросов в URL
                    if (news.sourceUrl.includes('[SID]') || 
                        news.sourceUrl.includes('[TID]') ||
                        news.sourceUrl.includes('%5BSID%5D') || 
                        news.sourceUrl.includes('%5BTID%5D')) {
                        // Если есть макросы, устанавливаем режим индивидуальных UTM-меток
                        utmSchema = 'custom';
                    } else {
                        // Если макросов нет, проверяем наличие стандартных UTM-параметров
                        const url = new URL(news.sourceUrl);
                        if (url.searchParams.has('utm_source') || url.searchParams.has('utm_content')) {
                            if (url.searchParams.get('utm_source') === window.location.hostname) {
                                utmSchema = 'standard';
                            } else {
                                utmSchema = 'custom';
                            }
                        }
                    }
                } catch (e) {
                    console.error('Ошибка при разборе URL:', e);
                }
            }
            
            console.log('Using UTM Schema:', utmSchema, 'from server:', news.utmSchema);
            
            // Если есть индивидуальные UTM параметры, загружаем их
            let customParams = {};
            if (news.customUtmParams) {
                try {
                    customParams = JSON.parse(news.customUtmParams);
                    console.log('Loaded custom UTM params:', customParams);
                } catch (e) {
                    console.error('Error parsing customUtmParams:', e);
                }
            }
            setCustomUtmParams(customParams);
            
            setFormData({
                title: news.title,
                sourceUrl: news.sourceUrl,
                language: news.language,
                utmSchema: utmSchema
            });
            
            setCurrentImage(news.imageUrl);
            setIsPaused(!news.isActive);
            setOrderNumber(news.orderNumber);
        } catch (err) {
            setError('Ошибка при загрузке объявления');
            console.error(err);
        }
    };

    // Handlers
    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        if (name === 'title' && value.length > MAX_TITLE_LENGTH) {
            return;
        }
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    }, []);

    const handleImageChange = useCallback((e) => {
        const file = e.target.files[0];
        if (!file) return;

        if (file.size > 5 * 1024 * 1024) {
            setError('Размер файла не должен превышать 5MB');
            return;
        }

        if (!['image/jpeg', 'image/png', 'image/webp'].includes(file.type)) {
            setError('Поддерживаются только форматы JPEG, PNG и WEBP');
            return;
        }

        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        
        img.onload = () => {
            if (img.width < 500 || img.height < 300) {
                setError('Изображение должно быть не меньше 500x300 пикселей');
                URL.revokeObjectURL(objectUrl);
                return;
            }
            
            setTempImage(objectUrl);
            setShowCropModal(true);
            setError('');
        };

        img.src = objectUrl;
    }, []);

    const handleCropComplete = useCallback((croppedImage) => {
        setImage(croppedImage);
        setPreview(URL.createObjectURL(croppedImage));
        setShowCropModal(false);
        setTempImage(null);
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');
        setFieldErrors({
            title: '',
            sourceUrl: '',
            image: ''
        });

        try {
            const formDataToSend = new FormData();
            formDataToSend.append('title', formData.title.trim());
            
            // Всегда сохраняем исходный URL без UTM-меток
            formDataToSend.append('sourceUrl', formData.sourceUrl);
            
            // Добавляем признак схемы UTM-меток, чтобы бэкенд мог выбрать нужную стратегию
            formDataToSend.append('utmSchema', formData.utmSchema);
            
            // Если используется индивидуальная схема UTM, добавляем параметры
            if (formData.utmSchema === 'custom' && Object.keys(customUtmParams).length > 0) {
                formDataToSend.append('customUtmParams', JSON.stringify(customUtmParams));
                console.log('Sending customUtmParams:', JSON.stringify(customUtmParams));
            }
            
            formDataToSend.append('language', formData.language);
            formDataToSend.append('isActive', !isPaused);
            
            if (image) {
                formDataToSend.append('image', image);
            }

            if (isEditing) {
                await api.put(`/admin/news/${id}`, formDataToSend);
            } else {
                await api.post('/admin/news', formDataToSend);
            }

            navigate('/admin/news');
        } catch (err) {
            if (err.errors) {
                setFieldErrors(err.errors);
            } else {
                setError(err.message || 'Произошла ошибка при сохранении новости');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleImageRemove = useCallback(() => {
        setPreview(null);
        setImage(null);
        setCurrentImage(null);
    }, []);

    // Функция для обработки UTM параметров
    const updateCustomUtmParam = (key, value) => {
        setCustomUtmParams(prev => {
            const newParams = { ...prev, [key]: value };
            console.log('Updated customUtmParams:', newParams);
            return newParams;
        });
    };
    
    // Удаление UTM параметра
    const removeCustomUtmParam = (key) => {
        setCustomUtmParams(prev => {
            const newParams = { ...prev };
            delete newParams[key];
            console.log('Removed param, updated customUtmParams:', newParams);
            return newParams;
        });
    };

    // Render
    return (
        <AdminLayout>
            {/* Стили для предупреждений */}
            <style dangerouslySetInnerHTML={{ __html: warningStyles }} />
            
            <div className="flex gap-10 p-5">
                {/* Form Section */}
                <div className="w-[500px]">
                    <h1 className="text-2xl text-blue-900 mb-8">
                        {isEditing ? 'Редактирование объявления' : 'Добавление объявления'}
                    </h1>
                    
                    <form onSubmit={handleSubmit}>
                        {/* Title Input */}
                        <div className="form-group">
                            <label className={`form-label ${fieldErrors.title ? 'form-label-error' : ''}`}>
                                Заголовок объявления
                            </label>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    required
                                    maxLength={MAX_TITLE_LENGTH}
                                    placeholder="Введите заголовок объявления"
                                    className={`form-input ${fieldErrors.title ? 'error' : ''}`}
                                />
                                <div className="character-counter" style={{ color: getCounterColor(formData.title.length, MAX_TITLE_LENGTH) }}>
                                    Осталось символов: {MAX_TITLE_LENGTH - formData.title.length}
                                </div>
                            </div>
                        </div>

                        {/* Source URL Input */}
                        <InputWrapper label="URL источника" error={fieldErrors.sourceUrl}>
                            <input
                                type="url"
                                name="sourceUrl"
                                value={formData.sourceUrl}
                                onChange={handleInputChange}
                                required
                                placeholder="https://example.com"
                                className="form-input"
                            />
                        </InputWrapper>
                        
                        {/* UTM метки */}
                        <div className="form-group">
                            <label className="form-label">Автоматические UTM-метки</label>
                            
                            <div className="utm-select">
                                <div 
                                    onClick={() => setShowUtmOptions(!showUtmOptions)}
                                    className="utm-select-header"
                                >
                                    <span>
                                        {formData.utmSchema === 'disabled' && 'UTM-метки отключены'}
                                        {formData.utmSchema === 'standard' && 'Стандартные UTM-метки'}
                                        {formData.utmSchema === 'custom' && 'Индивидуальные UTM-метки'}
                                    </span>
                                    <svg 
                                        className={`w-5 h-5 transition-transform ${showUtmOptions ? 'rotate-180' : ''}`} 
                                        fill="none" 
                                        stroke="currentColor" 
                                        viewBox="0 0 24 24"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </div>
                                
                                {showUtmOptions && (
                                    <div className="utm-options">
                                        <div 
                                            className="utm-option"
                                            onClick={() => handleUtmSchemaChange('disabled')}
                                        >
                                            UTM-метки отключены
                                        </div>
                                        <div 
                                            className="utm-option"
                                            onClick={() => handleUtmSchemaChange('standard')}
                                        >
                                            Стандартные UTM-метки
                                        </div>
                                        <div 
                                            className="utm-option"
                                            onClick={() => handleUtmSchemaChange('custom')}
                                        >
                                            Индивидуальные UTM-метки
                                        </div>
                                    </div>
                                )}
                            </div>
                            
                            {formData.utmSchema === 'custom' && (
                                <div className="utm-preview">
                                    <p><strong>Примечание:</strong> Макросы в UTM-параметрах будут автоматически заменены при переходе на сайт:</p>
                                    <ul className="utm-description">
                                        <li><strong>[SID]</strong> или <strong>%5BSID%5D</strong> → домен сайта (linenews.site)</li>
                                        <li><strong>[TID]</strong> или <strong>%5BTID%5D</strong> → порядковый номер объявления (ID в админке)</li>
                                    </ul>
                                    <p className="utm-description mt-1">Исходная ссылка сохраняется без изменений. UTM-параметры применяются только при переходе на сайт.</p>
                                    
                                    <div className="mt-4">
                                        <label className="block text-sm font-medium text-gray-700 mb-1">
                                            Добавить UTM-параметры, которые будут применены:
                                        </label>
                                        <div className="flex flex-col space-y-2">
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="addSidMacro"
                                                    className="h-4 w-4"
                                                    checked={customUtmParams.utm_medium === '[SID]'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            updateCustomUtmParam('utm_medium', '[SID]');
                                                        } else {
                                                            removeCustomUtmParam('utm_medium');
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="addSidMacro" className="text-sm">
                                                    Добавить utm_medium=[SID] (домен сайта)
                                                </label>
                                            </div>
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="checkbox"
                                                    id="addTidMacro"
                                                    className="h-4 w-4"
                                                    checked={customUtmParams.utm_content === '[TID]'}
                                                    onChange={(e) => {
                                                        if (e.target.checked) {
                                                            updateCustomUtmParam('utm_content', '[TID]');
                                                        } else {
                                                            removeCustomUtmParam('utm_content');
                                                        }
                                                    }}
                                                />
                                                <label htmlFor="addTidMacro" className="text-sm">
                                                    Добавить utm_content=[TID] (порядковый номер объявления)
                                                </label>
                                            </div>
                                            
                                            {/* Форма для добавления произвольных UTM-меток */}
                                            <div className="mt-3 border-t pt-3">
                                                <p className="text-sm font-medium text-gray-700 mb-2">
                                                    Добавить произвольный UTM-параметр:
                                                </p>
                                                <div className="flex items-center space-x-2">
                                                    <div className="flex-1">
                                                        <input
                                                            type="text"
                                                            id="customUtmName"
                                                            placeholder="Имя параметра: utm_term"
                                                            className="w-full p-2 border rounded text-sm"
                                                        />
                                                    </div>
                                                    <div className="flex-1">
                                                        <input
                                                            type="text"
                                                            id="customUtmValue"
                                                            placeholder="Значение: 123456"
                                                            className="w-full p-2 border rounded text-sm"
                                                        />
                                                    </div>
                                                    <button
                                                        type="button"
                                                        className="px-3 py-2 bg-blue-500 text-white rounded text-sm hover:bg-blue-600"
                                                        onClick={() => {
                                                            const paramName = document.getElementById('customUtmName').value.trim();
                                                            const paramValue = document.getElementById('customUtmValue').value.trim();
                                                            
                                                            if (paramName && paramValue) {
                                                                updateCustomUtmParam(paramName, paramValue);
                                                                
                                                                // Очищаем поля ввода
                                                                document.getElementById('customUtmName').value = '';
                                                                document.getElementById('customUtmValue').value = '';
                                                            } else {
                                                                setError('Укажите имя и значение UTM-параметра');
                                                            }
                                                        }}
                                                    >
                                                        Добавить
                                                    </button>
                                                </div>
                                            </div>
                                            
                                            {/* Текущие параметры URL */}
                                            {Object.keys(customUtmParams).length > 0 && (
                                                <div className="mt-4 border-t pt-3">
                                                    <p className="text-sm font-medium text-gray-700 mb-2">
                                                        Параметры, которые будут добавлены при переходе на сайт:
                                                    </p>
                                                    <div className="bg-gray-50 p-3 rounded border">
                                                        <div className="space-y-2">
                                                            {Object.entries(customUtmParams).map(([key, value], index) => (
                                                                <div key={index} className="flex items-center justify-between text-sm">
                                                                    <div>
                                                                        <span className="font-medium">{key}</span>
                                                                        <span className="mx-2">=</span>
                                                                        <span className="text-blue-600">{value}</span>
                                                                    </div>
                                                                    <button
                                                                        type="button"
                                                                        className="text-red-500 hover:text-red-700"
                                                                        onClick={() => removeCustomUtmParam(key)}
                                                                    >
                                                                        Удалить
                                                                    </button>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    
                                    <div className="utm-example mt-4">
                                        <div className="url-preview-header mb-2">Пример:</div>
                                        <div className="url-example-container">
                                            <div className="url-original p-3 bg-gray-50 border rounded-lg">
                                                <div className="text-xs text-gray-500 mb-1">Исходная ссылка сохраняется без изменений:</div>
                                                <code className="text-xs break-all">
                                                    {formData.sourceUrl || 'https://example.com/news/article'}
                                                </code>
                                            </div>
                                            
                                            <div className="flex justify-center my-2">
                                                <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                                                </svg>
                                            </div>
                                            
                                            <div className="url-transformed p-3 bg-blue-50 border border-blue-200 rounded-lg">
                                                <div className="text-xs text-blue-500 mb-1">При переходе на сайт добавляются UTM-параметры:</div>
                                                <code className="text-xs break-all">
                                                    {(() => {
                                                        const baseUrl = formData.sourceUrl || 'https://example.com/news/article';
                                                        const urlParts = [baseUrl];
                                                        
                                                        if (Object.keys(customUtmParams).length > 0) {
                                                            const queryChar = baseUrl.includes('?') ? '&' : '?';
                                                            const params = Object.entries(customUtmParams)
                                                                .map(([key, value]) => {
                                                                    const processedValue = value
                                                                        .replace(/\[SID\]/g, window.location.hostname)
                                                                        .replace(/\[TID\]/g, isEditing ? (orderNumber || '26') : '26');
                                                                    return `${key}=${processedValue}`;
                                                                })
                                                                .join('&');
                                                                
                                                            urlParts.push(queryChar, params);
                                                        }
                                                        
                                                        return urlParts.join('');
                                                    })()}
                                                </code>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            
                            {formData.utmSchema === 'standard' && (
                                <div className="utm-preview">
                                    <p><strong>Примечание:</strong> При переходе на сайт к URL будут автоматически добавлены следующие параметры:</p>
                                    <div className="utm-params">
                                        ?utm_source=linenews.site&utm_content=[TID]
                                    </div>
                                    <div className="utm-description">
                                        <p><strong>[TID]</strong> будет заменен на порядковый номер объявления (ID в админке)</p>
                                        <p><strong>utm_source</strong> будет установлен как домен сайта (linenews.site)</p>
                                    </div>
                                    
                                    {formData.sourceUrl && (formData.sourceUrl.includes('[SID]') || 
                                                           formData.sourceUrl.includes('[TID]') || 
                                                           formData.sourceUrl.includes('%5BSID%5D') || 
                                                           formData.sourceUrl.includes('%5BTID%5D')) && (
                                        <div className="warning-box mb-4 rounded-lg border-l-4 border-amber-500 bg-amber-50 p-4 shadow-sm mt-4">
                                            <div className="flex items-start">
                                                <div className="flex-shrink-0 mt-0.5">
                                                    <svg className="w-5 h-5 text-amber-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-amber-800">
                                                        Внимание! Обнаружен конфликт UTM-параметров
                                                    </h3>
                                                    <div className="mt-2 text-sm text-amber-700">
                                                        <p>Ваш URL содержит макросы, но выбран режим <strong>Стандартные UTM-метки</strong>:</p>
                                                        <ul className="list-disc list-inside space-y-1 mt-1 ml-2">
                                                            <li>Стандартные параметры: <code className="bg-white px-1 rounded">utm_source</code>, <code className="bg-white px-1 rounded">utm_content</code></li>
                                                            <li>Макросы в URL: 
                                                                {(formData.sourceUrl.includes('[SID]') || formData.sourceUrl.includes('%5BSID%5D')) && (
                                                                    <strong> [SID]</strong>
                                                                )}
                                                                {(formData.sourceUrl.includes('[SID]') || formData.sourceUrl.includes('%5BSID%5D')) && 
                                                                 (formData.sourceUrl.includes('[TID]') || formData.sourceUrl.includes('%5BTID%5D')) && (
                                                                    <span>,</span>
                                                                )}
                                                                {(formData.sourceUrl.includes('[TID]') || formData.sourceUrl.includes('%5BTID%5D')) && (
                                                                    <strong> [TID]</strong>
                                                                )}
                                                            </li>
                                                        </ul>
                                                        <p className="mt-2 text-sm font-medium bg-white rounded p-2 border border-amber-200">
                                                            👉 Для корректной работы макросов рекомендуется переключиться на режим <strong>"Индивидуальные UTM-метки"</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    
                                    <div className="utm-example mt-4">
                                        <div className="url-preview-header mb-2">Пример:</div>
                                        <div className="url-example-container">
                                            <div className="url-original p-3 bg-gray-50 border rounded-lg">
                                                <div className="text-xs text-gray-500 mb-1">Исходная ссылка:</div>
                                                <code className="text-xs break-all">
                                                    {formData.sourceUrl || 'reportbox.online/news/muzulmanki'}
                                                </code>
                                            </div>
                                            
                                            <div className="flex justify-center my-2">
                                                <svg className="w-6 h-6 text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                                                </svg>
                                            </div>
                                            
                                            <div className="url-transformed p-3 bg-blue-50 border border-blue-200 rounded-lg">
                                                <div className="text-xs text-blue-500 mb-1">После редиректа:</div>
                                                <code className="text-xs break-all">
                                                    {formData.sourceUrl ? 
                                                        `${formData.sourceUrl}${formData.sourceUrl.includes('?') ? '&' : '?'}utm_source=${window.location.hostname}&utm_content=${isEditing ? (orderNumber || '26') : '26'}`
                                                        : 'reportbox.online/news/muzulmanki?utm_source=linenews.site&utm_content=26'
                                                    }
                                                </code>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {formData.utmSchema === 'disabled' && (
                                <div className="utm-preview">
                                    <p className="text-sm text-gray-700">
                                        <svg className="inline w-5 h-5 mr-1 text-yellow-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                                        </svg>
                                        <strong>Примечание:</strong> Даже если UTM-метки отключены, макросы <strong>[SID]</strong> и <strong>[TID]</strong> в URL источника будут автоматически заменены при переходе на сайт
                                    </p>
                                    {/*
                                    <ul className="utm-description ml-6 mt-2">
                                        <li><strong>[SID]</strong> или <strong>%5BSID%5D</strong> → домен сайта (linenews.site)</li>
                                        <li><strong>[TID]</strong> или <strong>%5BTID%5D</strong> → порядковый номер объявления (ID в админке)</li>
                                    </ul>
                                    */}

                                    {formData.sourceUrl && (formData.sourceUrl.includes('[SID]') || 
                                                           formData.sourceUrl.includes('[TID]') || 
                                                           formData.sourceUrl.includes('%5BSID%5D') || 
                                                           formData.sourceUrl.includes('%5BTID%5D')) && (
                                        <div className="warning-box mb-4 rounded-lg border-l-4 border-amber-500 bg-amber-50 p-4 shadow-sm mt-4">
                                            <div className="flex items-start">
                                                <div className="flex-shrink-0 mt-0.5">
                                                    <svg className="w-5 h-5 text-amber-500" viewBox="0 0 20 20" fill="currentColor">
                                                        <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                                                    </svg>
                                                </div>
                                                <div className="ml-3">
                                                    <h3 className="text-sm font-medium text-amber-800">
                                                        Внимание! {
                                                            ((formData.sourceUrl.includes('[SID]') || formData.sourceUrl.includes('%5BSID%5D')) && 
                                                            (formData.sourceUrl.includes('[TID]') || formData.sourceUrl.includes('%5BTID%5D'))) ? 
                                                            'В вашем URL обнаружены макросы' : 'В вашем URL обнаружен макрос'
                                                        }
                                                    </h3>
                                                    <div className="mt-2 text-sm text-amber-700">
                                                        <p>Ваш URL содержит макросы, которые будут заменены:</p>
                                                        <ul className="list-disc list-inside space-y-1 mt-1 ml-2">
                                                            {(formData.sourceUrl.includes('[SID]') || formData.sourceUrl.includes('%5BSID%5D')) && (
                                                                <li><strong>[SID]</strong> будет заменен на <code className="bg-white px-1 rounded">linenews.site</code></li>
                                                            )}
                                                            {(formData.sourceUrl.includes('[TID]') || formData.sourceUrl.includes('%5BTID%5D')) && (
                                                                <li><strong>[TID]</strong> будет заменен на <code className="bg-white px-1 rounded">{isEditing ? (orderNumber || 'ID') : 'ID'}</code> объявления</li>
                                                            )}
                                                        </ul>
                                                        {/*
                                                        <p className="mt-2 text-sm font-medium bg-white rounded p-2 border border-amber-200">
                                                            👉 Для дополнительных UTM-параметров рекомендуется режим <strong>"Индивидуальные UTM-метки"</strong>
                                                        </p>
                                                        */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        {/* Language Select */}
                        <InputWrapper label="Язык">
                            <select
                                name="language"
                                value={formData.language}
                                onChange={handleInputChange}
                                className="form-select"
                            >
                                <option value="ru">Русский</option>
                                <option value="pl">Польский</option>
                                <option value="ro">Румынский</option>
                            </select>
                        </InputWrapper>

                        {/* Image Upload */}
                        <InputWrapper 
                            label={isEditing ? 'Изображение объявления' : 'Изображение объявления *'} 
                            error={fieldErrors.image}
                        >
                            <ImageUpload
                                currentImage={currentImage}
                                preview={preview}
                                onImageChange={handleImageChange}
                                onImageRemove={handleImageRemove}
                                isRequired={!isEditing}
                                isEditing={isEditing}
                            />
                        </InputWrapper>

                        {/* Error Message */}
                        {error && (
                            <div className="p-3 mb-4 text-red-600 bg-red-50 border border-red-200 rounded">
                                <strong>Ошибка: </strong>{error}
                            </div>
                        )}

                        {/* Form Actions */}
                        <div className="flex items-center justify-between mt-8">
                            <label className="flex items-center gap-2 text-sm text-gray-600 cursor-pointer">
                                <input
                                    type="checkbox"
                                    checked={isPaused}
                                    onChange={(e) => setIsPaused(e.target.checked)}
                                    className="w-4 h-4 rounded border-gray-300"
                                />
                                <span>Не запускать объявление</span>
                            </label>

                            <div className="flex gap-3">
                                <button
                                    type="button"
                                    onClick={() => navigate('/admin/news')}
                                    className="px-6 py-2 text-gray-600 bg-white border rounded hover:bg-gray-50"
                                >
                                    Отмена
                                </button>
                                <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="px-6 py-2 text-white bg-blue-600 rounded hover:bg-blue-700 disabled:bg-gray-400"
                                >
                                    {isLoading ? 'Сохранение...' : 'Сохранить'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                {/* Preview Section */}
                <div className="flex-1">
                    <h2 className="text-xl text-blue-900 mb-5">
                        Предпросмотр объявления
                    </h2>

                    <div className="max-w-xs">
                        <div className="flex gap-2 mb-2">
                            {['1:1', '3:2', '16:9'].map(ratio => (
                                <button
                                    key={ratio}
                                    onClick={() => setActiveAspectRatio(ratio)}
                                    className={`px-3 py-1 rounded ${
                                        activeAspectRatio === ratio 
                                            ? 'bg-blue-50 text-blue-600' 
                                            : 'bg-gray-100 text-gray-600'
                                    }`}
                                >
                                    {ratio}
                                </button>
                            ))}
                        </div>

                        <NewsPreview
                            image={preview || (currentImage ? `${BASE_DOMAIN}${currentImage}` : PLACEHOLDER_IMAGE)}
                            title={formData.title}
                            aspectRatio={activeAspectRatio}
                            placeholderText={PLACEHOLDER_TEXT}
                        />
                    </div>
                </div>
            </div>

            {/* Image Crop Modal */}
            {showCropModal && (
                <ImageCropModal
                    image={tempImage}
                    onComplete={handleCropComplete}
                    onCancel={() => {
                        setShowCropModal(false);
                        setTempImage(null);
                    }}
                />
            )}
        </AdminLayout>
    );
};

export default React.memo(NewsForm);