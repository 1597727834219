import React, { useState, useEffect } from 'react';
import { format, addMonths, subMonths, startOfMonth, endOfMonth, isWithinInterval, isSameDay, subDays } from 'date-fns';
import { ru } from 'date-fns/locale';
import axios from 'axios';
import CalendarIcon from '@mui/icons-material/CalendarToday';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const API_URL = '/api/admin/stats/historical';

const DateRangePicker = ({ onStatsChange, initialDate, defaultPeriod = 'today' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selecting, setSelecting] = useState(true);
    
    // Создадим функцию для получения сегодняшнего диапазона дат
    const getTodayRange = () => {
        const today = new Date();
        return {
            startDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0),
            endDate: new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
        };
    };
    
    const [selectedDate, setSelectedDate] = useState(() => {
        if (initialDate) {
            return initialDate;
        }
        
        const today = new Date();
        
        switch (defaultPeriod) {
            case 'last7days':
                const sevenDaysAgo = subDays(today, 6);
                const range = {
                    startDate: new Date(sevenDaysAgo.setHours(0, 0, 0, 0)),
                    endDate: new Date(today.setHours(23, 59, 59, 999))
                };
                return range;
            case 'today':
            default:
                const todayRange = getTodayRange();
                return todayRange;
        }
    });

    const [tempStartDate, setTempStartDate] = useState(null);

    useEffect(() => {
        if (selectedDate && typeof onStatsChange === 'function') {
            // Создаем чистые копии дат для инициализации
            const initialRange = {
                startDate: new Date(selectedDate.startDate.getTime()),
                endDate: new Date(selectedDate.endDate.getTime())
            };
            
            // Убедимся, что время установлено правильно
            initialRange.startDate.setHours(0, 0, 0, 0);
            initialRange.endDate.setHours(23, 59, 59, 999);
            
            fetchHistoricalStats(initialRange);
        }
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isOpen && !event.target.closest('.date-range-picker')) {
                setIsOpen(false);
                setSelecting(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const getDaysInMonth = (date) => {
        const start = startOfMonth(date);
        const end = endOfMonth(date);
        const days = [];

        const firstDayOfWeek = start.getDay() || 7;
        for (let i = 1; i < firstDayOfWeek; i++) {
            const prevDay = new Date(start);
            prevDay.setDate(1 - i);
            prevDay.setHours(0, 0, 0, 0);
            days.unshift({
                date: prevDay,
                isCurrentMonth: false
            });
        }

        let currentDay = new Date(start);
        while (currentDay <= end) {
            days.push({
                date: new Date(currentDay),
                isCurrentMonth: true
            });
            currentDay = new Date(currentDay);
            currentDay.setDate(currentDay.getDate() + 1);
        }

        const lastDayOfWeek = end.getDay();
        if (lastDayOfWeek !== 0) {
            for (let i = 1; i < 8 - lastDayOfWeek; i++) {
                const nextDay = new Date(end);
                nextDay.setDate(end.getDate() + i);
                nextDay.setHours(0, 0, 0, 0);
                days.push({
                    date: nextDay,
                    isCurrentMonth: false
                });
            }
        }

        return days;
    };

    const fetchHistoricalStats = async (dateRange) => {
        if (typeof onStatsChange !== 'function') {
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const formattedStartDate = format(dateRange.startDate, 'yyyy-MM-dd');
            const formattedEndDate = format(dateRange.endDate, 'yyyy-MM-dd');

            // Получаем ID новости из URL если мы на странице статистики
            const path = window.location.pathname;
            const newsId = path.includes('/news/') ? path.split('/news/')[1].split('/')[0] : null;

            const response = await axios.get('/api/admin/stats/news', {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                params: {
                    startDate: formattedStartDate,
                    endDate: formattedEndDate,
                    newsId
                }
            });
            
            if (response.data && Array.isArray(response.data.stats)) {
                onStatsChange(response.data.stats, dateRange);
            } else {
                onStatsChange([], dateRange);
            }
        } catch (error) {
            onStatsChange([], dateRange);
        }
    };

    const handleDayClick = (date) => {
        // Создаем копию даты, чтобы избежать мутации
        const clickedDate = new Date(date.getTime());
        
        if (selecting) {
            // Первый клик - выбираем начальную дату
            const startDate = new Date(clickedDate);
            startDate.setHours(0, 0, 0, 0);
            setTempStartDate(startDate);
            setSelecting(false);
        } else {
            // Второй клик - выбираем конечную дату
            const startDate = new Date(tempStartDate.getTime());
            const endDate = new Date(clickedDate);
            endDate.setHours(23, 59, 59, 999);
            
            // Проверяем, что конечная дата не раньше начальной
            if (endDate < startDate) {
                const finalRange = {
                    startDate: new Date(endDate.getTime()),
                    endDate: new Date(startDate.getTime())
                };
                finalRange.startDate.setHours(0, 0, 0, 0);
                finalRange.endDate.setHours(23, 59, 59, 999);
                setSelectedDate(finalRange);
            } else {
                const finalRange = { startDate, endDate };
                setSelectedDate(finalRange);
            }

            // Сбрасываем состояние выбора и закрываем календарь
            setTempStartDate(null);
            setSelecting(true);
            setIsOpen(false);

            // Вызываем колбэк с новым диапазоном дат
            if (typeof onStatsChange === 'function') {
                const rangeToFetch = endDate < startDate 
                    ? { startDate: new Date(endDate.getTime()), endDate: new Date(startDate.getTime()) } 
                    : { startDate, endDate };
                    
                fetchHistoricalStats(rangeToFetch);
            }
        }
    };

    const presetRanges = [
        { 
            label: 'Сегодня',
            getRange: () => {
                return getTodayRange();
            }
        },
        {
            label: 'Вчера',
            getRange: () => {
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                return {
                    startDate: new Date(yesterday.setHours(0, 0, 0, 0)),
                    endDate: new Date(yesterday.setHours(23, 59, 59, 999))
                };
            }
        },
        {
            label: 'Текущая неделя',
            getRange: () => {
                const today = new Date();
                const monday = new Date(today);
                monday.setDate(today.getDate() - today.getDay() + 1);
                return {
                    startDate: new Date(monday.setHours(0, 0, 0, 0)),
                    endDate: new Date(today.setHours(23, 59, 59, 999))
                };
            }
        },
        {
            label: 'Последние 7 дней',
            getRange: () => {
                const end = new Date();
                end.setHours(0, 0, 0, 0);
                const start = new Date(end);
                start.setDate(end.getDate() - 6);
                return {
                    startDate: new Date(start.setHours(0, 0, 0, 0)),
                    endDate: new Date(end.setHours(23, 59, 59, 999))
                };
            }
        },
        {
            label: 'Текущий месяц',
            getRange: () => {
                const today = new Date();
                const start = new Date(today.getFullYear(), today.getMonth(), 1);
                return {
                    startDate: new Date(start.setHours(0, 0, 0, 0)),
                    endDate: new Date(today.setHours(23, 59, 59, 999))
                };
            }
        },
        {
            label: 'Прошлый месяц',
            getRange: () => {
                const today = new Date();
                const start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const end = new Date(today.getFullYear(), today.getMonth(), 0);

                return {
                    startDate: new Date(start.setHours(0, 0, 0, 0)),
                    endDate: new Date(end.setHours(23, 59, 59, 999))
                };
            }
        },
        {
            label: 'Последние 30 дней',
            getRange: () => {
                const end = new Date();
                end.setHours(0, 0, 0, 0);
                const start = new Date(end);
                start.setDate(end.getDate() - 29);
                return {
                    startDate: new Date(start.setHours(0, 0, 0, 0)),
                    endDate: new Date(end.setHours(23, 59, 59, 999))
                };
            }
        }
    ];

    const handlePresetClick = (range) => {
        const dateRange = range.getRange();
        
        // Убедимся, что даты - это объекты Date и правильно заданы часы
        const cleanRange = {
            startDate: new Date(dateRange.startDate.getTime()),
            endDate: new Date(dateRange.endDate.getTime())
        };
        
        cleanRange.startDate.setHours(0, 0, 0, 0);
        cleanRange.endDate.setHours(23, 59, 59, 999);
        
        setSelectedDate(cleanRange);
        fetchHistoricalStats(cleanRange);
        setIsOpen(false);
    };

    // Функция для форматирования текста кнопки
    const getButtonText = () => {
        if (!selectedDate) return format(new Date(), 'dd.MM.yyyy');

        if (isSameDay(selectedDate.startDate, selectedDate.endDate)) {
            return format(selectedDate.startDate, 'dd.MM.yyyy');
        } 
        
        // Полный формат с годом для диапазона дат
        const startYear = selectedDate.startDate.getFullYear();
        const endYear = selectedDate.endDate.getFullYear();
        
        // Показываем годы для обеих дат, если годы разные
        if (startYear !== endYear) {
            return `${format(selectedDate.startDate, 'dd.MM.yyyy')} - ${format(selectedDate.endDate, 'dd.MM.yyyy')}`;
        }
        
        // Показываем год для обеих дат, когда они в одном году
        return `${format(selectedDate.startDate, 'dd.MM.yyyy')} - ${format(selectedDate.endDate, 'dd.MM.yyyy')}`;
    };

    // Эффект для отслеживания изменения размера окна
    useEffect(() => {
        const handleResize = () => {
            // Форсируем обновление компонента при изменении размера окна
            setForceUpdate(prev => !prev);
        };
        
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Состояние для принудительного обновления при изменении размера окна
    const [forceUpdate, setForceUpdate] = useState(false);

    // Определяем, расширен ли контейнер с датой (для диапазона дат)
    const isExpandedDateRange = selectedDate && !isSameDay(selectedDate.startDate, selectedDate.endDate);

    // Рассчитываем класс для кнопки с учетом необходимости расширения
    const buttonClass = isExpandedDateRange 
        ? "inline-flex items-center gap-2 px-3 py-2 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 date-range-expanded" 
        : "inline-flex items-center gap-2 px-3 py-2 bg-white border border-gray-200 rounded-lg hover:bg-gray-50";

    // Определяем стиль для кнопки в зависимости от длины текста
    const buttonStyle = isExpandedDateRange 
        ? { width: 'auto', minWidth: '220px', justifyContent: 'flex-start' } 
        : { justifyContent: 'center' };

    return (
        <div className="relative date-range-picker" style={isExpandedDateRange ? { width: 'auto', minWidth: '220px' } : {}}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                title={selectedDate ? `${format(selectedDate.startDate, 'dd.MM.yyyy')} - ${format(selectedDate.endDate, 'dd.MM.yyyy')}` : ''}
                className={buttonClass}
                style={buttonStyle}
            >
                <CalendarIcon className="w-5 h-5 text-gray-500 flex-shrink-0" />
                <span className={isExpandedDateRange ? "" : "truncate"}>
                    {getButtonText()}
                </span>
            </button>

            {isOpen && (
                <div className="absolute right-0 z-10 mt-2 bg-white rounded-lg shadow-lg w-[600px]">
                    <div className="p-2 text-sm text-gray-600 border-b">
                        {selecting 
                            ? "Выберите начальную дату" 
                            : "Выберите конечную дату"}
                        {tempStartDate && !selecting && (
                            <div className="mt-1 font-semibold">
                                Начальная дата: {format(tempStartDate, 'dd.MM.yyyy')}
                            </div>
                        )}
                    </div>
                    <div className="flex">
                        <div className="flex-1 p-4">
                            <div className="mb-4">
                                <div className="flex items-center justify-between mb-2">
                                    <h2 className="text-base font-semibold">
                                        {format(currentMonth, 'LLLL yyyy', { locale: ru })}
                                    </h2>
                                    <div className="flex gap-1">
                                        <button
                                            onClick={() => setCurrentMonth(subMonths(currentMonth, 1))}
                                            className="p-1 rounded hover:bg-gray-100"
                                        >
                                            <ChevronLeftIcon />
                                        </button>
                                        <button
                                            onClick={() => setCurrentMonth(addMonths(currentMonth, 1))}
                                            className="p-1 rounded hover:bg-gray-100"
                                        >
                                            <ChevronRightIcon />
                                        </button>
                                    </div>
                                </div>

                                <div className="grid grid-cols-7 mb-1">
                                    {['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'].map(day => (
                                        <div key={day} className="h-8 text-xs text-gray-500 font-medium flex items-center justify-center">
                                            {day}
                                        </div>
                                    ))}
                                </div>

                                <div className="grid grid-cols-7 gap-1">
                                    {getDaysInMonth(currentMonth).map(({ date, isCurrentMonth }, index) => {
                                        const isStartDate = selectedDate && isSameDay(date, selectedDate.startDate);
                                        const isEndDate = selectedDate && isSameDay(date, selectedDate.endDate);
                                        const isSelected = isStartDate || isEndDate;
                                        
                                        // Проверяем, находится ли дата в выбранном диапазоне
                                        const isInRange = selectedDate && 
                                            date >= selectedDate.startDate && 
                                            date <= selectedDate.endDate;
                                        
                                        // Проверяем, находится ли дата во временном диапазоне (при выборе)
                                        const isInTempRange = tempStartDate && 
                                            date >= tempStartDate && 
                                            date <= new Date();
                                                
                                        // Изменено: даты будущих месяцев тоже можно выбирать
                                        const isDisabled = false;

                                        return (
                                            <button
                                                key={index}
                                                onClick={() => !isDisabled && handleDayClick(date)}
                                                disabled={isDisabled}
                                                className={`
                                                    w-full p-2 text-center text-sm rounded
                                                    ${!isCurrentMonth ? 'text-gray-400' : 'text-gray-900'}
                                                    ${isSelected ? 'bg-blue-600 text-white' : ''}
                                                    ${(isInRange || isInTempRange) && !isSelected ? 'bg-blue-100' : ''}
                                                    ${!isDisabled ? 'hover:bg-gray-100' : 'opacity-50 cursor-not-allowed'}
                                                `}
                                            >
                                                {date.getDate()}
                                            </button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className="w-48 border-l border-gray-200 p-4">
                            <h3 className="text-xs font-semibold text-gray-900 mb-3">
                                Быстрый выбор
                            </h3>
                            <div className="space-y-1 flex flex-col">
                                {presetRanges.map((range, index) => (
                                    <button
                                        key={index}
                                        onClick={() => handlePresetClick(range)}
                                        className="w-full text-left px-2 py-1.5 text-sm text-gray-700 hover:bg-gray-100 rounded"
                                    >
                                        {range.label}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DateRangePicker;