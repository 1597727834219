import React, { useState, useEffect } from 'react';
import axios from 'axios';

const NewsGrid = ({ newsItems }) => {
    const chunks = [];
    let index = 0;

    while (index < newsItems.length) {
        // Добавляем 4 квадратных блока
        if (index + 4 <= newsItems.length) {
            chunks.push({
                type: 'square',
                items: newsItems.slice(index, index + 4)
            });
            index += 4;
        } else {
            chunks.push({
                type: 'square',
                items: newsItems.slice(index)
            });
            break;
        }

        // Добавляем 2 прямоугольных блока
        if (index + 2 <= newsItems.length) {
            chunks.push({
                type: 'rectangle',
                items: newsItems.slice(index, index + 2)
            });
            index += 2;
        } else if (index < newsItems.length) {
            chunks.push({
                type: 'rectangle',
                items: newsItems.slice(index)
            });
            break;
        }
    }

    return (
        <div className="flex flex-col gap-2">
            {chunks.map((chunk, chunkIndex) => (
                <div 
                    key={chunkIndex} 
                    className={`grid gap-2 ${
                        chunk.type === 'square' 
                            ? 'grid-cols-1 sm:grid-cols-2 lg:grid-cols-4' 
                            : 'grid-cols-1 lg:grid-cols-2'
                    }`}
                >
                    {chunk.items.map((item) => (
                        <div
                            key={item._id}
                            className="p-[2px]"
                            style={{ 
                                aspectRatio: chunk.type === 'square' ? '2.5/3' : '16/9'
                            }}
                        >
                            <div className="teaser group relative w-full h-full overflow-hidden rounded-[3px]">
                                {/* Основное изображение */}
                                <div className="item-image relative w-full h-full">
                                <img
                                    src={`${process.env.REACT_APP_FULL_DOMAIN}${item.imageUrl}`}
                                    alt={item.title}
                                    className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                                />
                                </div>

                                {/* Градиент только снизу */}
                                <div className="absolute bottom-0 left-0 right-0 h-[180px] bg-gradient-to-t from-black/90 via-black/50 to-transparent pointer-events-none transition-opacity duration-300 group-hover:opacity-90" />
                                
                                {/* Заголовок с обновленными стилями */}
                                <div className="absolute bottom-0 left-0 right-0 w-full p-[18px_14px] m-0 z-40 pointer-events-none">
                                    <h3 className="news-title text-white font-bold text-center text-[16px] sm:text-[17px] lg:text-[18px] leading-[1.2]">
                                        {item.title}
                                    </h3>
                                </div>

                                {/* Ссылка поверх всего */}
                                <a
                                    href={item.sourceUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="absolute inset-0 z-10"
                                />
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const TopNews = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchActiveNews = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/public/news/active`);
                setNews(response.data);
                setLoading(false);
            } catch (err) {
                console.error('Error fetching news:', err);
                setError(err.response?.data?.message || 'Failed to load news');
                setLoading(false);
            }
        };
    
        fetchActiveNews();
    }, []);
    

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-xl text-gray-600">
                    Загрузка...
                </div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-xl text-red-600">
                    {error}
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-100 py-8 px-4">
            <div className="max-w-[1080px] mx-auto">
                <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-8">
                    Активные новости
                </h1>
                {news.length > 0 ? (
                    <NewsGrid newsItems={news} />
                ) : (
                    <div className="text-center text-xl text-gray-600">
                        Активных новостей не найдено
                    </div>
                )}
            </div>
        </div>
    );
};

export default TopNews;